import { svgToCanvas } from '@orangelv/utils-dom'
import { s } from 'hastscript'
import { Font, load as loadFont } from 'opentype.js'

import { renderText } from '@orangelv/svg-renderer'
import { svgScale, svgTranslate } from '@orangelv/utils-svg'

const TEXTURE_WIDTH = 1024
const TEXTURE_HEIGHT = 1024

const USEFUL_WIDTH = 0.6 * TEXTURE_WIDTH
const SIZE = 192
const VERTICAL_OFFSET = -32

let fontPromise: Promise<Font> | undefined

const getTextCanvas =
  (text: string, color: string) => async (canvas: HTMLCanvasElement) => {
    if (!fontPromise) {
      // Arimo is metrically compatible with Arial, the original
      fontPromise = loadFont(
        'https://fonts.gstatic.com/s/arimo/v29/P5sfzZCDf9_T_3cV7NCUECyoxNk3CstsBxDAVQI4aA.ttf',
      )
    }

    const svg = renderText(await fontPromise, text, { size: SIZE, fill: color })
    const scale = Math.min(1, USEFUL_WIDTH / svg.width)

    const svgData = {
      name: '',
      width: TEXTURE_WIDTH,
      height: TEXTURE_HEIGHT,
      root: s(
        'g',
        {
          transform:
            svgTranslate(
              (TEXTURE_WIDTH - svg.width * scale) / 2,
              (TEXTURE_HEIGHT - svg.height * scale) / 2 + VERTICAL_OFFSET,
            ) + svgScale(scale, scale),
        },
        svg.root,
      ),
    }

    return svgToCanvas(svgData, { canvas })
  }

export default getTextCanvas
