// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { OutsoleVariantRow } from '../typings';

const data = [
  {
    "id": "metal",
    "name": "Metal",
    "price": {
      "USD": 155.55,
      "EUR": 166.66,
      "CAD": 166.66
    }
  },
  {
    "id": "molded",
    "name": "Molded",
    "price": {
      "USD": 122.22,
      "EUR": 133.33,
      "CAD": 133.33
    }
  },
  {
    "id": "allSurface",
    "name": "All Surface (AS) Turf",
    "price": {
      "USD": 122.22,
      "EUR": 133.33,
      "CAD": 133.33
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<OutsoleVariantRow[]>> = data;

export type OutsoleVariant = typeof typed[number];

export const OUTSOLE_VARIANT_INDEX_KEY = "id";
export type OutsoleVariantIndexKey = "id";
export type OutsoleVariantId = OutsoleVariant["id"];

let i = 0;
export const OUTSOLE_VARIANT_DICT = {
  "metal": typed[i++],
  "molded": typed[i++],
  "allSurface": typed[i++]
} as const;

export { typed as OUTSOLE_VARIANTS };
