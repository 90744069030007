import { ReactNode } from 'react'

import './InfoBox.css'

const HEADERS = {
  information: 'Information',
  warning: 'Warning',
  error: 'Error',
} as const

const InfoBox = ({
  children,
  type = 'information',
  customHeader,
}: {
  children: ReactNode
  type?: keyof typeof HEADERS
  customHeader?: string
}) => (
  <div className={`InfoBox InfoBox--${type}`}>
    <div className="InfoBox-header">{customHeader ?? HEADERS[type]}</div>
    <div>{children}</div>
  </div>
)

export default InfoBox
