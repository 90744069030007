import { hasLengthAtLeast } from '@orangelv/utils';
import { round } from '@orangelv/utils-arithmetic';
import {
  cm2px,
  in2px,
  pt2px,
  px2in,
  type Vector2,
} from '@orangelv/utils-geometry';
import {
  parseSvgPathData,
  simplifyPathData,
  stringifySvgPathData,
} from '@orangelv/utils-svg-path-data';
import type { Element, Properties, Root } from 'hast';
import { select, selectAll } from 'hast-util-select';
import { toHtml } from 'hast-util-to-html';
import hash from 'object-hash';
import { parse } from 'svg-parser';
import { SKIP, visit, type VisitorResult } from 'unist-util-visit';

/**
 * Contains SVG syntax tree and related information.
 */
export type SvgData = {
  /**
   * A somewhat unique name for the SVG. Usually derived from file name.
   */
  name: string;
  /**
   * SVG syntax tree root.
   */
  root: Root | Element;
  /**
   * Width in user units aka "pixels".
   */
  width: number;
  /**
   * Height in user units aka "pixels".
   */
  height: number;
};

/**
 * Create SVG translate string.
 * @param x - translate by x
 * @param y - translate by y
 * @returns SVG translate string.
 * @public
 */
export function svgTranslate(x: number, y: number): string {
  return `translate(${x},${y}) `;
}

/**
 * Create SVG rotate string.
 * @param angle - rotate by this angle
 * @param cx - optionally rotate around c(x,y) point
 * @param cy - optionally rotate around c(x,y) point
 * @returns  SVG rotate string.
 * @public
 */
export function svgRotate(angle: number, cx?: number, cy?: number): string {
  const haveX = cx !== undefined;
  const haveY = cy !== undefined;
  if ((haveX && !haveY) || (!haveX && haveY)) {
    throw new Error('Bad arguments');
  }

  if (haveX && haveY) {
    return `rotate(${angle} ${cx} ${cy}) `;
  }

  return `rotate(${angle}) `;
}

/**
 * Create SVG scale string.
 * @param x - scale x factor
 * @param y - scale y factor
 * @returns  SVG scale string.
 * @public
 */
export function svgScale(x: number, y: number): string {
  return `scale(${x},${y}) `;
}

/**
 * Convert list of points to SVG Polyline.
 * @param points - List of {@link Vector2}.
 * @returns SVG Polyline string.
 * @public
 */
export const toSVGPolyline = (points: Vector2[]): string =>
  points.map(({ x, y }) => `${x},${y}`).join(' ');

function lengthToPixels(x: Properties[number]): number {
  if (typeof x === 'number') return x;
  if (typeof x !== 'string') throw new Error('Bad property');

  const value = Number(x.slice(0, -2));
  switch (x.slice(-2)) {
    case 'cm': {
      return cm2px(value);
    }

    case 'in': {
      return in2px(value);
    }

    case 'mm': {
      return cm2px(value / 10);
    }

    case 'pc': {
      return pt2px(value * 12);
    }

    case 'pt': {
      return pt2px(value);
    }

    case 'px': {
      return value;
    }

    case 'Q': {
      return cm2px(value / 40);
    }

    default: {
      // It's parsed as a number if it's unitless
      throw new Error('Unrecognized unit');
    }
  }
}

/**
 * Parse string into {@link SvgData}.
 *
 * @param svg - SVG string
 * @param name - name of the SVG asset
 * @returns parsed SVG as SvgData.
 * @public
 */
export function stringToSvg(svgString: string, name: string): SvgData {
  const root = parse(svgString) as Root;
  if (!hasLengthAtLeast(root.children, 1)) throw new Error('Empty SVG');

  const svg = root.children[0];
  if (svg.type !== 'element' || svg.tagName !== 'svg') {
    throw new Error('Not an SVG');
  }

  let width;
  let height;

  if (
    svg.properties['width'] !== undefined &&
    svg.properties['height'] !== undefined
  ) {
    width = lengthToPixels(svg.properties['width']);
    height = lengthToPixels(svg.properties['height']);
  } else if (typeof svg.properties['viewBox'] === 'string') {
    // Fallback to parsing viewBox only when width or height is not set.

    // The value of the ‘viewBox’ attribute is a list of four numbers <min-x>,
    // <min-y>, <width> and <height>, separated by whitespace and/or a comma.
    const widthHeight = svg.properties['viewBox']
      .split(/[\s,|]/u)
      .filter((n) => n !== '')
      .map((n) => Number.parseFloat(n))
      .slice(2);

    if (!hasLengthAtLeast(widthHeight, 2)) {
      throw new Error('Could not parse viewBox');
    }

    [width, height] = widthHeight;
  } else {
    throw new TypeError('Must contain `width` and `height` and/or `viewBox`');
  }

  // This is a hacky way to determine whether given SVG is a full custom
  // template. For templates, we don't want to do any PPI changes even when
  // saved with Illustrator.
  const isFullCustomTemplate = !!select('g#full-custom-template', svg);

  // This might break things. We need to reach into the undocumented. Also
  // related unmerged PR: https://github.com/Rich-Harris/svg-parser/pull/15
  const svgElementWithMeta = svg as Element & { metadata?: string };
  if (
    typeof svgElementWithMeta.metadata === 'string' &&
    svgElementWithMeta.metadata.includes('Generator: Adobe Illustrator') &&
    !isFullCustomTemplate
  ) {
    // This will only work if viewBox is set so we set it. This might screw up
    // artworks which are using weird coordinates like not starting at (0,0).
    if (typeof svg.properties['height'] !== 'string') {
      svg.properties['viewBox'] = `0 0 ${width} ${height}`;
    }

    // Convert Illustrator 72 PPI values into proper ones.
    width = pt2px(width);
    height = pt2px(height);
  }

  // Make sure SVG has actual size
  svg.properties['width'] = `${px2in(width)}in`;
  svg.properties['height'] = `${px2in(height)}in`;

  // There's a bug in svg-parser where it doesn't convert "class" attributes to
  // "className" when creating a hast tree. This goes over all elements and
  // fixes that so that we can use class selectors with
  // matches/select/selectAll.
  for (const element of selectAll('*', svg)) {
    if ('class' in element.properties) {
      element.properties['className'] = element.properties['class'];
      delete element.properties['class'];
    }
  }

  return {
    width,
    height,
    root,
    name,
  };
}

export function isSvgDocument(svg: SvgData): boolean {
  const firstChild = svg.root.type === 'root' ? svg.root.children[0] : svg.root;
  return (
    firstChild !== undefined &&
    firstChild.type === 'element' &&
    firstChild.tagName === 'svg'
  );
}

/**
 * Convert {@link SvgData} to string.
 * @param svg - SVG object
 * @returns a string representation of {@link SvgData}.
 * @public
 */
export function svgToString(svg: SvgData): string {
  return toHtml(
    isSvgDocument(svg) ?
      svg.root
    : <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={svg.width}
        height={svg.height}
      >
        {svg.root}
      </svg>,
    { space: 'svg' },
  );
}

/**
 * Colorize SVG.
 * @param svg - SVG object to colorize
 * @param colors - A mapping between SVG element selectors and fill colors
 * @example
 * ```ts
 * colorizeSvg(mySvg, {'#firstId: "#FF0000", '#secondId: '#00FF00'});
 * ```
 * @returns a **copy** of original SVG with colors applied.
 * @public
 */
export function colorizeSvg(
  svg: SvgData,
  colors: Record<string, string>,
): SvgData {
  const colorized = structuredClone(svg);

  // Make id unique based on color selection
  colorized.name = `${svg.name}-${hash(colors)}`;

  for (const [selector, color] of Object.entries(colors)) {
    const elements = selectAll(selector, colorized.root);
    for (const element of elements) {
      element.properties['fill'] = color;
    }
  }

  return colorized;
}

/**
 * Get SVG Element from SvgData and assert it's valid.
 *
 * @param svg - SvgData to get the element from
 * @returns SVG Element
 */
function getSvgElement(svg: SvgData): Element & {
  properties: NonNullable<Element['properties']> & { viewBox: string };
} {
  const svgElement = svg.root.children[0];
  if (
    svgElement === undefined ||
    !('properties' in svgElement) ||
    typeof svgElement.properties['viewBox'] !== 'string'
  ) {
    throw new Error('Bad SVG');
  }

  // https://stackoverflow.com/a/57929239/242684
  return svgElement as ReturnType<typeof getSvgElement>;
}

/**
 * Stretch without deformation.
 * @param svg - input SVG data
 * @param x - stretch x magnitude
 * @param y - stretch y magnitude
 * @internal
 */
export function smartStretch(svg: SvgData, x: number, y: number): void {
  const cX = svg.width / 2;
  const cY = svg.height / 2;
  svg.width += x;
  svg.height += y;

  const svgElement = getSvgElement(svg);
  svgElement.properties['width'] = `${round(px2in(svg.width), 5)}in`;
  svgElement.properties['height'] = `${round(px2in(svg.height), 5)}in`;
  const vb = svgElement.properties.viewBox.split(' ').map(Number);
  if (!hasLengthAtLeast(vb, 4)) throw new Error('Bad viewBox');
  vb[2] += x;
  vb[3] += y;
  svgElement.properties.viewBox = vb.join(' ');

  function tX(oX: number): number {
    return round(oX + (x / 2) * (oX > cX ? 1 : -1), 5);
  }

  function tY(oY: number): number {
    return round(oY + (y / 2) * (oY > cY ? 1 : -1), 5);
  }

  const polys = selectAll('polygon', svg.root);
  for (const p of polys) {
    if (typeof p.properties['points'] === 'string') {
      const pString = p.properties['points'];
      const points = pString.split(' ');
      let output = '';
      for (let index = 0; index < points.length; index += 2) {
        const nX = tX(Number(points[index]));
        const nY = tY(Number(points[index + 1]));

        output += `${nX} `;
        output += `${nY} `;
      }

      p.properties['points'] = output.trim();
      p.properties['transform'] = svgTranslate(x / 2, y / 2);
    } else {
      throw new TypeError('Bad SVG');
    }
  }

  const paths = selectAll('path', svg.root);
  for (const p of paths) {
    if (typeof p.properties['d'] === 'string') {
      const pathData = simplifyPathData(parseSvgPathData(p.properties['d']));
      const stretchedPathData = pathData.map((command) => {
        const commandNew = structuredClone(command);
        switch (commandNew.type) {
          case 'Z': {
            break;
          }

          case 'L':
          case 'M': {
            commandNew.values[0] = tX(commandNew.values[0]);
            commandNew.values[1] = tY(commandNew.values[1]);
            break;
          }

          case 'Q': {
            commandNew.values[0] = tX(commandNew.values[0]);
            commandNew.values[1] = tY(commandNew.values[1]);
            commandNew.values[2] = tX(commandNew.values[2]);
            commandNew.values[3] = tY(commandNew.values[3]);
            break;
          }

          case 'C': {
            commandNew.values[0] = tX(commandNew.values[0]);
            commandNew.values[1] = tY(commandNew.values[1]);
            commandNew.values[2] = tX(commandNew.values[2]);
            commandNew.values[3] = tY(commandNew.values[3]);
            commandNew.values[4] = tX(commandNew.values[4]);
            commandNew.values[5] = tY(commandNew.values[5]);
            break;
          }
        }

        return commandNew;
      });
      p.properties['d'] = stringifySvgPathData(stretchedPathData);
      p.properties['transform'] = svgTranslate(x / 2, y / 2);
    } else {
      throw new TypeError('Bad SVG');
    }
  }
}

/**
 * Uniform scale.
 *
 * @param svg - SVG to scale
 * @param ratio - Scale ratio
 */
export function scale(svg: SvgData, ratio: number): void {
  svg.width *= ratio;
  svg.height *= ratio;
  const svgElement = getSvgElement(svg);
  svgElement.properties['width'] = `${round(px2in(svg.width), 5)}in`;
  svgElement.properties['height'] = `${round(px2in(svg.height), 5)}in`;
}

export function cleanup(svg: SvgData): SvgData {
  const patternsById: Record<string, Element> = {};

  visit(svg.root, 'element', (node, index, parent): VisitorResult => {
    // We don't care about elements without parent.
    if (!parent || index === undefined) return true;

    // Don't visit embedded SVGs.
    if (parent.type !== 'root' && node.tagName === 'svg') return SKIP;

    // We only care about `pattern` for now.
    if (node.tagName !== 'pattern') return true;

    if (typeof node.properties['id'] === 'string') {
      const { id } = node.properties;
      if (id in patternsById) {
        // Delete pattens which have duplicate ID with other patterns.
        parent.children.splice(index, 1);
        return [SKIP, index];
      }

      patternsById[id] = node;
    }

    return true;
  });

  return svg;
}

const appendNamespace = (x: string, namespace: number | string): string =>
  `${x}-UNI0mnnnjkQbKxM6-${namespace}`; // cspell:disable-line

const isolateIdsInUrls = (x: string, namespace: number | string): string =>
  x.replaceAll(
    /url\(["']?#(?<y>.*?)["']?\)/gu,
    (_, y: string) => `url(#${encodeURI(appendNamespace(y, namespace))})`,
  );

export function isolateIds(svg: SvgData, namespace: number | string): void {
  visit(svg.root, (node) => {
    if (node.type !== 'element') return;

    // Fix IDs in `style` tag
    if (
      node.tagName === 'style' &&
      hasLengthAtLeast(node.children, 1) &&
      node.children[0].type === 'text'
    ) {
      node.children[0].value = isolateIdsInUrls(
        node.children[0].value,
        namespace,
      );
    }

    // Fix IDs
    if (typeof node.properties['id'] === 'string') {
      node.properties['id'] = appendNamespace(node.properties['id'], namespace);
    }

    // Fix `href="#id"` and `xlink:href="#id"`
    for (const propertyName of ['href', 'xlink:href']) {
      const x = node.properties[propertyName];
      if (typeof x !== 'string' || !x.startsWith('#')) continue;
      node.properties[propertyName] = appendNamespace(x, namespace);
    }

    // Fix `url(#id)`
    for (const propertyName of [
      'clip-path',
      'clipPath',
      'fill',
      'marker',
      'marker-end',
      'marker-mid',
      'marker-start',
      'markerEnd',
      'markerMid',
      'markerStart',
      'mask',
      'shape-inside',
      'shape-subtract',
      'shapeInside',
      'shapeSubtract',
      'stroke',
      'style',
    ]) {
      const x = node.properties[propertyName];
      if (typeof x !== 'string') continue;
      node.properties[propertyName] = isolateIdsInUrls(x, namespace);
    }
  });
}
