import fp from 'lodash/fp.js'
import { concat } from 'redux-fp'

import controlTree from './controlTree'
// import sheets from '../common/sheets'

type State = any

const initialState = {
  closedBlocks: {},
}

const getDefaultState = () => (state: State) => fp.assign(initialState, state)

// const guaranteeSheets = () => state =>
//   state.sheets ? state : fp.set('sheets', sheets)(state)

export default concat(
  getDefaultState,
  // guaranteeSheets,
  controlTree.updater,
)

export type { State }
