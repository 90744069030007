// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { SolidColorRow } from '../typings';

const data = [
  {
    "id": "yellow",
    "name": "Yellow",
    "props": {
      "technicalName": "Blazing Yellow - 12-0643 TCX",
      "hex": "#fef829"
    }
  },
  {
    "id": "purple",
    "name": "Purple",
    "props": {
      "technicalName": "Purple - 266 C",
      "hex": "#4a2d5b"
    }
  },
  {
    "id": "white",
    "name": "White",
    "props": {
      "technicalName": "White",
      "hex": "#f8f8f8"
    }
  },
  {
    "id": "navy",
    "name": "Navy",
    "props": {
      "technicalName": "Dress Blue - 19-4024 TCX",
      "hex": "#323e63"
    }
  },
  {
    "id": "orange",
    "name": "Orange",
    "props": {
      "technicalName": "Orange - 1585 C",
      "hex": "#d64d1c"
    }
  },
  {
    "id": "red",
    "name": "Red",
    "props": {
      "technicalName": "Red - 186 C",
      "hex": "#9c1e1d"
    }
  },
  {
    "id": "lightBlue",
    "name": "Light Blue",
    "props": {
      "technicalName": "Columbia Blue - 2169 C",
      "hex": "#41B6E6"
    }
  },
  {
    "id": "maroon",
    "name": "Maroon",
    "props": {
      "technicalName": "505 C",
      "hex": "#8C1D40"
    }
  },
  {
    "id": "forestGreen",
    "name": "Forest Green",
    "props": {
      "technicalName": "3308 C",
      "hex": "#245f56"
    }
  },
  {
    "id": "kellyGreen",
    "name": "Kelly Green",
    "props": {
      "technicalName": "Kelly Green - 355 C",
      "hex": "#359967"
    }
  },
  {
    "id": "black",
    "name": "Black",
    "props": {
      "technicalName": "Black",
      "hex": "#313133"
    }
  },
  {
    "id": "royal",
    "name": "Royal",
    "props": {
      "technicalName": "287 C",
      "hex": "#1d54ac"
    }
  },
  {
    "id": "gray",
    "name": "Gray",
    "props": {
      "technicalName": "High Rise - 15-4101 TCX",
      "hex": "#aeb2b5"
    }
  },
  {
    "id": "silver",
    "name": "Silver",
    "props": {
      "technicalName": "Silver(High Rise)- 15-4101 tcx",
      "hex": "#C0C0C0"
    }
  },
  {
    "id": "vegasGold",
    "name": "Vegas Gold",
    "props": {
      "technicalName": "871 C",
      "hex": "#9b8a36"
    }
  },
  {
    "id": "darkShadow",
    "name": "Dark Shadow",
    "props": {
      "technicalName": "19-3906 TPX",
      "hex": "#5e595d"
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<SolidColorRow[]>> = data;

export type SolidColor = typeof typed[number];

export const SOLID_COLOR_INDEX_KEY = "id";
export type SolidColorIndexKey = "id";
export type SolidColorId = SolidColor["id"];

let i = 0;
export const SOLID_COLOR_DICT = {
  "yellow": typed[i++],
  "purple": typed[i++],
  "white": typed[i++],
  "navy": typed[i++],
  "orange": typed[i++],
  "red": typed[i++],
  "lightBlue": typed[i++],
  "maroon": typed[i++],
  "forestGreen": typed[i++],
  "kellyGreen": typed[i++],
  "black": typed[i++],
  "royal": typed[i++],
  "gray": typed[i++],
  "silver": typed[i++],
  "vegasGold": typed[i++],
  "darkShadow": typed[i++]
} as const;

export { typed as SOLID_COLORS };
