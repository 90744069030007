import type { Tuple } from '@orangelv/utils';
import type { Vector2 } from '@orangelv/utils-geometry';
import {
  boundingBoxToQuad,
  getPathDataBoundingBox,
  type MinimalCommand,
  projectPathData,
  type Quad,
} from '@orangelv/utils-svg-path-data';

type Config = {
  topEdge: number;
  topCenter: number;
  bottomEdge: number;
  bottomCenter: number;
};

type GetRatio = (textWidth: number, posX: number) => number;

type LayoutFunction = (
  pathData: MinimalCommand[],
  textWidth: number,
  ascenderHeight: number,
  isEdge?: boolean,
) => [pathData: MinimalCommand[], source: Quad, destination: Quad];

function getCircleGraph(
  offsetEdge: number,
  offsetCenter: number,
  getRatio: GetRatio,
): GetRatio {
  const dx = 1;
  const dy = offsetEdge - offsetCenter;

  if (dy === 0) return () => -offsetCenter;

  const ang = Math.atan2(dx, dy);
  const length = Math.hypot(dx, dy);

  const angOpp = Math.PI - 2 * ang;

  // sin opp / radius = sin ang / length
  const radius = (length / Math.sin(angOpp)) * Math.sin(ang);
  const offset = offsetCenter + radius;

  return (textWidth, x) => {
    const xNew = getRatio(textWidth, x);
    const y = Math.sqrt(radius ** 2 - xNew ** 2); // radius^2 = x^2 + y^2
    return y - offset;
  };
}

function getArchLayout(config: Config, getRatio: GetRatio): LayoutFunction {
  const graphTop = getCircleGraph(config.topEdge, config.topCenter, getRatio);
  const graphBottom = getCircleGraph(
    config.bottomEdge,
    config.bottomCenter,
    getRatio,
  );

  return (pathData, textWidth, ascenderHeight) => {
    const bb = getPathDataBoundingBox(pathData);
    const source = boundingBoxToQuad(bb);
    const destination = boundingBoxToQuad(bb);
    const { x, width: w } = bb;

    destination.topLeft.y -= ascenderHeight * graphTop(textWidth, x);
    destination.topRight.y -= ascenderHeight * graphTop(textWidth, x + w);

    destination.bottomLeft.y -= ascenderHeight * graphBottom(textWidth, x);
    destination.bottomRight.y -= ascenderHeight * graphBottom(textWidth, x + w);

    return [
      projectPathData(pathData, source, destination),
      source,
      destination,
    ];
  };
}

function getArchLayoutAltEdge(
  config: { middle: Config; edge: Config },
  getRatio: GetRatio,
): LayoutFunction {
  const middleLayout = getArchLayout(config.middle, getRatio);
  const edgeLayout = getArchLayout(config.edge, getRatio);
  return (pathData, textWidth, ascenderHeight, isEdge) =>
    isEdge ?
      edgeLayout(pathData, textWidth, ascenderHeight)
    : middleLayout(pathData, textWidth, ascenderHeight);
}

function getArchRotateLayout(
  config: Config,
  getRatio: GetRatio,
): LayoutFunction {
  const graphTop = getCircleGraph(config.topEdge, config.topCenter, getRatio);
  const graphBottom = getCircleGraph(
    config.bottomEdge,
    config.bottomCenter,
    getRatio,
  );

  return (pathData, textWidth, ascenderHeight) => {
    const bb = getPathDataBoundingBox(pathData);
    const source = boundingBoxToQuad(bb);
    const destination = boundingBoxToQuad(bb);
    const { x, width: w, height: h } = bb;

    destination.topLeft.y -= ascenderHeight * graphTop(textWidth, x);
    destination.topRight.y -= ascenderHeight * graphTop(textWidth, x + w);

    destination.bottomLeft.y -= ascenderHeight * graphBottom(textWidth, x);
    destination.bottomRight.y -= ascenderHeight * graphBottom(textWidth, x + w);

    //  Determine the angle from the bottom line
    const lengthAB = destination.bottomRight.x - destination.bottomLeft.x;
    const lengthAC = destination.bottomRight.y - destination.bottomLeft.y;
    const angleABC = Math.asin(lengthAC / lengthAB);

    //  Calculate offset based on angle and text height
    const radius = h * (1 - config.topEdge);
    const offsetY = Math.cos(angleABC) * -radius + h;
    const offsetX = Math.sin(angleABC) * radius;

    //  Apply offsets
    destination.topLeft.x = destination.bottomLeft.x + offsetX;
    destination.topLeft.y = destination.bottomLeft.y + offsetY - h;
    destination.topRight.x = destination.bottomRight.x + offsetX;
    destination.topRight.y = destination.bottomRight.y + offsetY - h;

    return [
      projectPathData(pathData, source, destination),
      source,
      destination,
    ];
  };
}

const getDefaultRatio: GetRatio = (textWidth, x) => (x / textWidth) * 2 - 1;

const ARCHED_BOOKENDS_ARCH_HEIGHT = 0.441;
const ARCHED_BOOKENDS_LETTER_HEIGHT = 0.932;
const ARCHED_BOOKENDS_TOTAL_HEIGHT = 1.655;
const ARCHED_BOOKENDS_TOP_CENTER = -0.3;
const ARCHED_BOOKENDS_BOTTOM_CENTER =
  ARCHED_BOOKENDS_TOP_CENTER + ARCHED_BOOKENDS_LETTER_HEIGHT - 1;
const ARCHED_BOOKENDS_CORNER_BOTTOM_CENTER =
  ARCHED_BOOKENDS_TOP_CENTER +
  ARCHED_BOOKENDS_TOTAL_HEIGHT -
  ARCHED_BOOKENDS_ARCH_HEIGHT -
  1;

const SWEEP_TOP_CENTER = -0.6;
const SWEEP_SMALL_HEIGHT = 0.9;
const SWEEP_LARGE_HEIGHT = 1.9;
const SWEEP_ARCH_HEIGHT = 0.444;

/** @internal */
export const LAYOUTS = {
  arched: getArchRotateLayout(
    {
      topEdge: 0,
      topCenter: 0,
      bottomEdge: 0.192,
      bottomCenter: -0.192,
    },
    getDefaultRatio,
  ),

  archedBookends: getArchLayoutAltEdge(
    {
      middle: {
        topCenter: ARCHED_BOOKENDS_TOP_CENTER,
        topEdge: ARCHED_BOOKENDS_TOP_CENTER + ARCHED_BOOKENDS_ARCH_HEIGHT,
        bottomCenter: ARCHED_BOOKENDS_BOTTOM_CENTER,
        bottomEdge: ARCHED_BOOKENDS_BOTTOM_CENTER + ARCHED_BOOKENDS_ARCH_HEIGHT,
      },
      edge: {
        topCenter: ARCHED_BOOKENDS_TOP_CENTER,
        topEdge: ARCHED_BOOKENDS_TOP_CENTER + ARCHED_BOOKENDS_ARCH_HEIGHT,
        bottomCenter: ARCHED_BOOKENDS_CORNER_BOTTOM_CENTER,
        bottomEdge:
          ARCHED_BOOKENDS_CORNER_BOTTOM_CENTER + ARCHED_BOOKENDS_ARCH_HEIGHT,
      },
    },
    getDefaultRatio,
  ),

  bridge: getArchLayout(
    {
      topEdge: 0,
      topCenter: 0,
      bottomEdge: 0.365,
      bottomCenter: -0.048,
    },
    getDefaultRatio,
  ),

  bridgeBookends: getArchLayoutAltEdge(
    {
      middle: {
        topEdge: 0,
        topCenter: 0,
        bottomEdge: 0.34,
        bottomCenter: -0.183,
      },
      edge: {
        topEdge: 0,
        topCenter: 0,
        bottomEdge: 0.34,
        bottomCenter: 0.34,
      },
    },
    getDefaultRatio,
  ),

  reversedBookends: getArchLayoutAltEdge(
    {
      middle: {
        topEdge: 0,
        topCenter: 0,
        bottomEdge: 0,
        bottomCenter: 0,
      },
      edge: {
        topEdge: -0.34,
        topCenter: -0.34,
        bottomEdge: 0,
        bottomCenter: 0,
      },
    },
    getDefaultRatio,
  ),

  sweep: getArchLayout(
    {
      topCenter: SWEEP_TOP_CENTER,
      topEdge: SWEEP_TOP_CENTER + SWEEP_ARCH_HEIGHT,
      bottomCenter: SWEEP_TOP_CENTER + SWEEP_SMALL_HEIGHT - 1,
      bottomEdge: SWEEP_TOP_CENTER + SWEEP_ARCH_HEIGHT + SWEEP_LARGE_HEIGHT - 1,
    },
    (textWidth, x) => 1 - x / textWidth,
  ),

  verticalArch: getArchLayout(
    {
      topEdge: 0.25,
      topCenter: -0.25,
      bottomEdge: 0.215,
      bottomCenter: -0.285,
    },
    getDefaultRatio,
  ),
} as const;

export type TextLayout = keyof typeof LAYOUTS;

export function makeLayout(
  layout: TextLayout,
  pathDataGroup: MinimalCommand[][],
  textWidth: number,
  ascenderHeight: number,
): [pathDataGroup: MinimalCommand[][], baselineDelta: Tuple<Vector2, 2>] {
  const layoutFunction = LAYOUTS[layout];
  const baselineDelta: Tuple<Vector2, 2> = [
    { x: 0, y: 0 },
    { x: 0, y: 0 },
  ];
  const pathDataGroupNew = pathDataGroup.map((pathData, index) => {
    const isFirst = index === 0;
    const isLast = index === pathDataGroup.length - 1;
    const [pathDataNew, source, destination] = layoutFunction(
      pathData,
      textWidth,
      ascenderHeight,
      isFirst || isLast,
    );

    if (isFirst) {
      baselineDelta[0].x = destination.bottomLeft.x - source.bottomLeft.x;
      baselineDelta[0].y = destination.bottomLeft.y - source.bottomLeft.y;
    }

    if (isLast) {
      baselineDelta[1].x = destination.bottomRight.x - source.bottomRight.x;
      baselineDelta[1].y = destination.bottomRight.y - source.bottomRight.y;
    }

    return pathDataNew;
  });

  return [pathDataGroupNew, baselineDelta];
}
