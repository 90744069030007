import * as _ from '@technically/lodash'

import getAsset from '../../../platform/getAsset'

import { RendererConfig } from '@orangelv/bjs-renderer'
import getTextCanvas from './getTextCanvas'
import getCamoCanvas from './getCamoCanvas'

// TODO: Type this.
type Nodes = any

const getRendererConfig = (nodes: Nodes): RendererConfig => {
  const product = nodes['product.sku'].object

  const outsoleColor = nodes['colors.outsole'].value

  const toeTongueCollarHex = nodes['colors.toeTongueCollar'].object.props.hex
  const midsoleHex = nodes['colors.midsole'].object.props.hex
  const liningHex = nodes['colors.lining'].object.props.hex
  const vampHex = nodes['colors.vamp'].object.props.hex
  const midfootColor =
    nodes['colors.midfootColor'].isAvailable &&
    nodes['colors.midfootColor'].object.props.hex
  const midfootCamo =
    nodes['colors.midfootCamouflage'].isAvailable &&
    nodes['colors.midfootCamouflage'].object.props.hex
  const midfootRunbirdHex = nodes['colors.midfootRunbird'].object.props.hex
  const shoelaceHex = nodes['colors.shoelace'].object.props.hex
  const heelHex = nodes['colors.heel'].object.props.hex
  const tongueRunbirdHex = nodes['colors.tongueRunbird'].object.props.hex
  const tongueTopHex = nodes['colors.tongueTop'].object.props.hex

  const decoRunbird = nodes['decoration.type'].value === 'runbird'
  const decoHex =
    nodes['decoration.color'].isAvailable &&
    nodes['decoration.color'].object.props.hex
  const decoText =
    nodes['decoration.text'].isAvailable && nodes['decoration.text'].value
  const decoLogo =
    nodes['decoration.previewFile'].isAvailable &&
    nodes['decoration.previewFile'].value

  const isLC = product.props.cut === 'low'
  const isMC = product.props.cut === 'mid'

  const isMetalOutsole = product.props.outsole === 'metal'
  const isMoldedOutsole = product.props.outsole === 'molded'
  const isAllSurfaceOutsole = product.props.outsole === 'allSurface'

  const getMidfootMat = () => {
    if (midfootColor) {
      return {
        diffuseColor: midfootColor,
      }
    }
    if (midfootCamo) {
      return {
        diffuseTexture: {
          getCanvas: getCamoCanvas(midfootCamo),
          key: midfootCamo,
        },
      }
    }
    return undefined
  }

  const getHeelCustomMat = () => {
    if (decoLogo) {
      return {
        diffuseTexture: {
          url: `/api/images/${decoLogo.id}/${decoLogo.filename}`,
        },
      }
    }
  }

  const getHeelTextMat = () => {
    if (decoText) {
      return {
        diffuseTexture: {
          getCanvas: getTextCanvas(decoText, decoHex),
          key: [decoText, decoHex],
        },
      }
    }
  }

  const midfootMat = getMidfootMat()

  const cutMaterials =
    isLC ?
      {
        tongueLCMat: {
          diffuseColor: toeTongueCollarHex,
        },
        collarLCMat: {
          diffuseColor: toeTongueCollarHex,
        },
        liningLCMat: {
          diffuseColor: liningHex,
        },
        midfootLCMat: midfootMat,
        midfootSideLCMat: midfootMat,
        tongueInnerLCMat: {
          diffuseColor: toeTongueCollarHex,
        },
        shoelaceLCMat: {
          diffuseColor: shoelaceHex,
        },
        tongueTopLCMat: {
          diffuseColor: tongueTopHex,
        },
      }
    : {
        tongueMCMat: {
          diffuseColor: toeTongueCollarHex,
        },
        collarMCMat: {
          diffuseColor: toeTongueCollarHex,
        },
        liningMCMat: {
          diffuseColor: liningHex,
        },
        midfootMCMat: midfootMat,
        midfootSideMCMat: midfootMat,
        tongueInnerMCMat: {
          diffuseColor: toeTongueCollarHex,
        },
        shoelaceMCMat: {
          diffuseColor: shoelaceHex,
        },
        tongueTopMCMat: {
          diffuseColor: tongueTopHex,
        },
      }

  return {
    scene: {
      backgroundColor: '#e5e5e5',
      environmentTexture: getAsset('studio.env'),
    },
    camera: {
      defaultCamera: 'side',
      lowerBeta: Math.PI / 2 - Math.PI / 2 / 4,
      upperBeta: Math.PI / 2 + Math.PI / 2 / 4,
      lowerRadius: 50,
      upperRadius: 100,
    },
    models: {
      cleats: {
        url: getAsset('cleats.gltf'),
        defaultPosition: { y: -6 },
        meshes: {
          cutLowCtrl: {
            isVisible: isLC,
          },
          cutMidCtrl: {
            isVisible: isMC,
          },
          soleMetalCtrl: {
            isVisible: isMetalOutsole,
          },
          soleMoldedCtrl: {
            isVisible: isMoldedOutsole,
          },
          soleTurfCtrl: {
            isVisible: isAllSurfaceOutsole,
          },
          outsoleMetalSilver: {
            isVisible: outsoleColor === 'sole_silver',
          },
          outsoleMetalSilverChrome: {
            isVisible: outsoleColor === 'sole_silverChrome',
          },
          soleMetalGround: {
            isVisible: isMetalOutsole,
          },
          soleMoldedGround: {
            isVisible: isMoldedOutsole,
          },
          soleTurfGround: {
            isVisible: isAllSurfaceOutsole,
          },
          heelRunbird: {
            isVisible: decoRunbird,
          },
          heelCustom: {
            isVisible: !decoText && !!decoLogo,
          },
          heelText: {
            isVisible: !!decoText,
          },
        },
        materials: _.mapValues(
          {
            ...cutMaterials,
            toeMat: {
              diffuseColor: toeTongueCollarHex,
            },
            midsoleMat: {
              diffuseColor: midsoleHex,
            },
            vampMat: {
              diffuseColor: vampHex,
            },
            midfootRunbirdMat: {
              diffuseColor: midfootRunbirdHex,
            },
            tongueRunbirdMat: {
              diffuseColor: tongueRunbirdHex,
            },
            heelMat: {
              diffuseColor: heelHex,
            },
            heelRunbirdMat: decoHex && {
              diffuseColor: decoHex,
            },
            heelCustomMat: getHeelCustomMat(),
            heelTextMat: getHeelTextMat(),
          },
          // Map false to undefined to do nothing with the material.
          (value) => value || undefined,
        ),
      },
    },
  }
}

export default getRendererConfig
