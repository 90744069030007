import { svgStringToCanvas } from '@orangelv/utils-dom'

import assert from '../../../platform/assert'
import getAsset from '../../../platform/getAsset'
import http from '../../../platform/client/http'

const TEXTURE_WIDTH = 1024
const TEXTURE_HEIGHT = 1024

let camoPromise: Promise<string> | undefined = undefined

const getCamoCanvas =
  (colors: string[]) => async (canvas: HTMLCanvasElement) => {
    if (!camoPromise) {
      camoPromise = new Promise((resolve, reject) => {
        http
          .get(getAsset('camo.svg'))
          .then((response) => resolve(response.data))
          .catch(reject)
      })
    }

    const svgString = await camoPromise

    const svgDom = document.createElement('div')
    svgDom.innerHTML = svgString

    const $color1 = svgDom.querySelector('#color1')
    const $color2 = svgDom.querySelector('#color2')
    const $color3 = svgDom.querySelector('#color3')

    assert($color1, 'Could not find #color1!')
    assert($color2, 'Could not find #color2!')
    assert($color3, 'Could not find #color3!')

    $color1.setAttribute('fill', colors[0])
    $color2.setAttribute('fill', colors[1])
    $color3.setAttribute('fill', colors[2])

    const patternCanvas = await svgStringToCanvas(svgDom.innerHTML)

    const context = canvas.getContext('2d')
    assert(context)

    const pattern = context.createPattern(patternCanvas, 'repeat')

    assert(pattern, 'Could not create pattern!')

    canvas.width = TEXTURE_WIDTH
    canvas.height = TEXTURE_HEIGHT

    context.fillStyle = pattern
    context.fillRect(0, 0, canvas.width, canvas.height)
  }

export default getCamoCanvas
