import 'handjs'
import * as _ from '@technically/lodash'
import fp from 'lodash/fp.js'
import React, { useState } from 'react'
import { createRoot } from 'react-dom/client'
import { connect } from 'react-redux'
import WebFont from 'webfontloader'
import useResizeObserver from 'use-resize-observer/polyfilled'
import viewportUnitsBuggyfill from 'viewport-units-buggyfill'

import handleErrors from '../../../platform/client/handleErrors'
import * as history from '../../../platform/client/history'
import getAsset from '../../../platform/getAsset'
import {
  isRecipeFinalizedSelector,
  isSkuSelector,
  createMenuSelector,
  getPreviewUrlsSelector,
  isAppLoadingSelector,
} from '../../../platform/client/common/selectors'
import {
  togglePreviewMinimization,
  ensurePreviewIntroduced,
  setOriginValues,
  loadRecipe,
  saveRecipe,
  modifyRecipe,
  toggleShare,
  setPreviewMinimization,
  copyLinkToClipboard,
  setPreviewGenerator,
} from '../../../platform/client/common/actions'
import Application from '../../../platform/client/containers/Application'
import ErrorBoundary from '../../../platform/client/components/ErrorBoundary'
import { connectOrders } from '../../../platform/client/component-enhancers'
import { cn, states } from '../../../platform/client/components/utils'
import PreviewImages from '../../../platform/client/components/PreviewImages'
import * as controlTreeDebugUtils from '../../../platform/client/control-tree/debugUtils'
import configureRouter from '../../../platform/client/configureRouter'

import Layout from '../../_mizuno/client/components/Layout'
import Sidebar from '../../_mizuno/client/components/Sidebar'
import ProductSet from '../../_mizuno/client/components/molecules/ProductSet'
import ProductTile from '../../_mizuno/client/components/molecules/ProductTile'
import _ProductTitle from '../../_mizuno/client/components/organisms/ProductTitle'
import SocialIcons from '../../_mizuno/client/containers/SocialIcons'
import Summary from '../../_mizuno/client/components/Summary'

import ContactForm from '../../mizuno-uniforms/client/containers/ContactForm'
import _Finalize from '../../mizuno-uniforms/client/containers/Finalize'

import controlTree from './controlTree'
import viewAngles, { defaultViewAngleId } from '../viewAngles'
import store from './store'
import { State } from './updater'
import { Renderer } from '../renderer'
import getRendererConfig from '../renderer/getRendererConfig'

import '~mizuno/client/components/Root.css'
import './index.css'

handleErrors(store)

// For debugging.
window['store'] = store

// One should be using control-tree/debugUtils via window like window.getNodes().
window['getControlTree'] = () => controlTree
_.forEach(controlTreeDebugUtils.createUtils(store, controlTree), (fn, name) => {
  window[name] = fn
})

WebFont.load({
  google: {
    families: ['Roboto:300,400,500,700'],
  },
})

viewportUnitsBuggyfill.init()

const routes = [
  [
    '/design/:recipeId(/:status)',
    ({ recipeId }) => /[a-z0-9]+/.test(recipeId) && recipeId.length >= 3,
  ],
  '/sku/:sku',
]

const { initialMatches } = configureRouter(controlTree, store, routes)

const initialState = store.getState()

const getValuesFromSku = (sku: string) => {
  const values = {
    'product.sku': sku,
  }

  return values
}

if (initialMatches && initialMatches.recipeId) {
  store.dispatch(loadRecipe(controlTree, initialMatches.recipeId))
} else if (initialMatches && isSkuSelector(initialState)) {
  const { sku } = initialMatches
  store.dispatch(controlTree.setValues(getValuesFromSku(sku)))
  store.dispatch(setOriginValues(controlTree))
} else {
  store.dispatch(setOriginValues(controlTree))
}

window.addEventListener('message', (ev) => {
  if (fp.has('type', ev.data)) {
    store.dispatch(ev.data)
  }
})

{
  const mql = window.matchMedia('(min-width: 1000px)')
  if (mql.matches) store.dispatch(setPreviewMinimization(false))
  mql.addListener((x) => store.dispatch(setPreviewMinimization(!x.matches)))
}

const ProductTitle = connectOrders(controlTree, _ProductTitle)
const Finalize = connectOrders(controlTree, _Finalize)

const menuSelector = createMenuSelector(controlTree)

const mapStateToProps = (state: State) => {
  const nodes = controlTree.getNodes(state)

  const productName = nodes['product.sku'].optionName
  const products = nodes['filters.products'].visibleOptions

  return {
    nodes,
    isAppLoading: isAppLoadingSelector(state),
    isRecipeFinalized: isRecipeFinalizedSelector(state),
    isPreviewMinimized: state.isPreviewMinimized,
    previewUrls: getPreviewUrlsSelector(viewAngles, defaultViewAngleId)(state),
    menu: menuSelector(state),
    products,
    expandedRecipe: controlTree.getExpandedRecipe(state),
    productName,
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveRecipe: () => {
    const nodes = controlTree.getNodes(store.getState())

    const isRosterValidNode = nodes['calc.isRosterValid']

    if (!isRosterValidNode.value) {
      history.push({ query: { menu: 'details/roster', rosterError: 1 } })
      return
    }

    history.push({ query: { rosterError: undefined } })

    dispatch(saveRecipe(controlTree))
  },
  modifyRecipe: () => dispatch(modifyRecipe()),
  copyLinkToClipboard: () => dispatch(copyLinkToClipboard()),
  togglePreviewMinimization: () => dispatch(togglePreviewMinimization()),
  toggleShare: () => dispatch(toggleShare()),
  setPreviewGenerator: (generator: any) =>
    dispatch(setPreviewGenerator(generator)),
  changeProduct: (sku: string) => {
    dispatch(ensurePreviewIntroduced())

    dispatch(controlTree.setValues(getValuesFromSku(sku)))

    history.push({ path: `/sku/${sku}`, query: { menu: null } })
  },
})

const getStyledProductName = (productName: string) => {
  const line1 = 'MIZUNO SPECTRA'
  const [, line2] = productName.split(`${line1} `)
  return (
    <span>
      {line1}
      <br />
      {line2}
    </span>
  )
}

const Root = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: any) => {
  const rendererResizer = useResizeObserver()

  const isProductScreen = props.menu === 'product' && !props.isRecipeFinalized
  const isFinalizeScreen = props.isRecipeFinalized

  const isRendererHidden = isProductScreen || isFinalizeScreen

  const [isRendererLoading, setRendererLoading] = useState(true)

  return (
    <Layout
      {...props}
      isProductScreen={isProductScreen}
      isFinalizeScreen={isFinalizeScreen}
    >
      <ProductTitle
        previewState={props.isPreviewMinimized ? 'off' : 'on'}
        title={
          props.expandedRecipe['details.recipeName.text'] || 'My custom cleats'
        }
        name={props.productName}
        saveButtonLabel="Save my cleats"
        onSave={() => {
          if (isRendererLoading) {
            return
          }
          props.saveRecipe()
        }}
        onShare={() => {
          props.copyLinkToClipboard()
        }}
        onPreviewToggle={() => props.togglePreviewMinimization()}
        priceFormatted={props.nodes['calc.priceFormatted'].value}
        notes={['Standard Lead Time 10-12 Weeks']}
      />

      <div className="viewer">
        <div className="preview">
          <div
            ref={rendererResizer.ref}
            className={cn([
              'renderer',
              states([props.isPreviewMinimized ? 'off' : 'on']),
            ])}
          >
            <Renderer
              config={getRendererConfig(props.nodes)}
              isHidden={isRendererHidden}
              autoResize={`${isRendererHidden}/${rendererResizer.width}/${rendererResizer.height}`}
              setPreviewGenerator={props.setPreviewGenerator}
              isLoading={isRendererLoading}
              setLoading={setRendererLoading}
            />

            {isFinalizeScreen && props.isPreviewMinimized && (
              <img
                className="preview-image-corner"
                src={_.values(props.previewUrls)[0]}
              />
            )}

            {isFinalizeScreen && !props.isPreviewMinimized && (
              <div className="preview-images-outer">
                {!window.serverConfig?.hideSocial && (
                  <SocialIcons
                    classMods={['largeScreen']}
                    downloadUrl={props.previewUrls[defaultViewAngleId]}
                  />
                )}
                <PreviewImages previewUrls={props.previewUrls} />
              </div>
            )}
          </div>
        </div>

        <div className="productSelect">
          <ProductSet>
            {_.map(props.products, (product) => (
              <ProductTile
                key={product.id}
                name={getStyledProductName(product.name)}
                title={product.id}
                onClick={() => {
                  props.changeProduct(product.id)
                }}
                imageUrl={getAsset(`icons/products/${product.id}.png`)}
                buttonText="Build these cleats"
              />
            ))}
          </ProductSet>
        </div>
      </div>

      <div className="sidebar">
        <div className="sidebar-body">
          <Sidebar nodes={props.nodes} />
        </div>
      </div>

      <Finalize
        controlTree={controlTree}
        onSave={() => {
          if (isRendererLoading) {
            return
          }

          props.saveRecipe()
        }}
        resetMenu="colors"
        saveButtonLabel="Save my cleats"
        resumeButtonLabel="Edit cleats"
      >
        {isFinalizeScreen && !props.isAppLoading && (
          <>
            <ContactForm />
            <Summary nodes={props.nodes} />
          </>
        )}
      </Finalize>
    </Layout>
  )
})

function onReady() {
  createRoot(document.getElementById('root')!).render(
    <Application store={store}>
      <ErrorBoundary>
        <Root />
      </ErrorBoundary>
    </Application>,
  )
}

document.addEventListener('DOMContentLoaded', onReady)
