import * as _ from '@technically/lodash'
import fp from 'lodash/fp.js'
import FileSaver from 'file-saver'
import JSZip from 'jszip'

import { Camera, GeneratePreviews } from '@orangelv/bjs-renderer'

import { Props } from './'
import { PRODUCTS } from '../common/sheets'
import controlTree from '../client/controlTree'
import getRendererConfig from './getRendererConfig'

const allInvisibleGroundMeshes = {
  soleMetalGround: { isVisible: false },
  soleMoldedGround: { isVisible: false },
  soleTurfGround: { isVisible: false },
}

const generateIcon =
  (
    generatePreviews: GeneratePreviews,
    propsRef: { current: Props },
    defaultView: string,
  ) =>
  async (name = 'cleats') => {
    const rendererConfig = _.defaultsDeep(
      {},
      {
        models: {
          cleats: {
            meshes: allInvisibleGroundMeshes,
          },
        },
      },
      propsRef.current.config,
    )

    const blobs = await generatePreviews({
      cameraIds: [defaultView],
      size: { width: 512, height: 512 },
      rendererConfig,
    })

    const blob = blobs[defaultView]

    FileSaver.saveAs(blob, `${name}.png`)
  }

const generateIcons =
  (
    generatePreviews: GeneratePreviews,
    _propsRef: { current: Props },
    defaultView: string,
  ) =>
  async () => {
    const relevantProducts = PRODUCTS.filter(
      (product) => product.props.isEnabled,
    )

    const state = {
      controlTree: {
        pendingChanges: { auto: {}, user: {} },
        preferredValues: {},
        repeatedNodes: {},
        values: {},
      },
    }

    const zip = new JSZip()
    const folder = zip.folder('icons')!

    for (const product of relevantProducts) {
      const nodes = controlTree.getNodes(
        fp.set(['controlTree', 'values', 'product.sku'], product.id)(state),
      )

      const blobs = await generatePreviews({
        cameraIds: [defaultView],
        size: { width: 512, height: 512 },
        rendererConfig: _.defaultsDeep(
          {},
          {
            models: {
              cleats: { meshes: allInvisibleGroundMeshes },
            },
          },
          getRendererConfig(nodes),
        ),
      })

      const blob = blobs[defaultView]

      folder.file(`${product.id}.png`, blob)
    }

    const content = await zip.generateAsync({ type: 'blob' })
    FileSaver.saveAs(content, 'icons.zip')
  }

const onGeneratePreviews =
  (propsRef: { current: Props }, defaultView: string) =>
  (generatePreviews: GeneratePreviews) => {
    propsRef.current.setPreviewGenerator((viewIds: Camera['id'][]) => {
      const rendererConfig = _.defaultsDeep(
        {},
        {
          models: {
            cleats: {
              meshes: allInvisibleGroundMeshes,
            },
          },
        },
        propsRef.current.config,
      )

      return generatePreviews({
        cameraIds: viewIds,
        size: { width: 1024, height: 1024 },
        rendererConfig,
      })
    })

    const w = window as any

    w.generateIcon = generateIcon(generatePreviews, propsRef, defaultView)
    w.generateIcons = generateIcons(generatePreviews, propsRef, defaultView)
  }

export default onGeneratePreviews
