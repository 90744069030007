import { ok } from '@orangelv/utils';

import { getState, setModelState } from './state.js';
import { LogLevel, type Ref, type State } from './types.js';

const unloadModel =
  (stateRef: Ref<State>) =>
  (modelId: string): void => {
    const { props, models } = getState(stateRef);

    if (props.onLog) {
      props.onLog(LogLevel.Info, `Unloading ${modelId}`);
    }

    const modelState = models[modelId];

    ok(modelState);

    const {
      assetContainer,
      allMeshes,
      originalMaterials,
      dynamicTextures,
      fabrics,
    } = modelState;

    assetContainer.dispose();

    const doNotRecurse = true;
    const disposeMaterialAndTextures = true;
    for (const mesh of allMeshes) {
      mesh.dispose(doNotRecurse, disposeMaterialAndTextures);
    }

    for (const x of Object.values(originalMaterials)) x.dispose();

    for (const materialTextures of Object.values(dynamicTextures)) {
      for (const x of Object.values(materialTextures)) x.dispose();
    }

    for (const x of Object.values(fabrics)) x.dispose();

    setModelState(stateRef)(modelId);
  };

export default unloadModel;
