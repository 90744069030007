// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { SummaryTreeItemRow } from '../typings';

const data = [
  {
    "childId": "product",
    "name": "Product",
    "id": "product"
  },
  {
    "parentId": "product",
    "childId": "gender",
    "name": "Gender",
    "propId": "calc.gender",
    "id": "product.gender"
  },
  {
    "parentId": "product",
    "childId": "cut",
    "name": "Cut",
    "propId": "calc.cut",
    "id": "product.cut"
  },
  {
    "parentId": "product",
    "childId": "outsole",
    "name": "Sole",
    "propId": "calc.outsole",
    "id": "product.outsole"
  },
  {
    "childId": "colors",
    "name": "Colors",
    "id": "colors"
  },
  {
    "parentId": "colors",
    "childId": "midsole",
    "name": "Midsole",
    "propId": "colors.midsole",
    "id": "colors.midsole"
  },
  {
    "parentId": "colors",
    "childId": "lining",
    "name": "Lining",
    "propId": "colors.lining",
    "id": "colors.lining"
  },
  {
    "parentId": "colors",
    "childId": "toeTongueCollar",
    "name": "Toe, Tongue, Collar",
    "propId": "colors.toeTongueCollar",
    "id": "colors.toeTongueCollar"
  },
  {
    "parentId": "colors",
    "childId": "vamp",
    "name": "Vamp",
    "propId": "colors.vamp",
    "id": "colors.vamp"
  },
  {
    "parentId": "colors",
    "childId": "midfootType",
    "name": "Midfoot Type",
    "propId": "colors.midfootType",
    "id": "colors.midfootType"
  },
  {
    "parentId": "colors",
    "childId": "midfootColor",
    "name": "Midfoot Color",
    "propId": "colors.midfootColor",
    "id": "colors.midfootColor"
  },
  {
    "parentId": "colors",
    "childId": "midfootCamouflage",
    "name": "Midfoot Camouflage",
    "propId": "colors.midfootCamouflage",
    "id": "colors.midfootCamouflage"
  },
  {
    "parentId": "colors",
    "childId": "midfootRunbird",
    "name": "Midfoot Runbird",
    "propId": "colors.midfootRunbird",
    "id": "colors.midfootRunbird"
  },
  {
    "parentId": "colors",
    "childId": "shoelace",
    "name": "Shoelace",
    "propId": "colors.shoelace",
    "id": "colors.shoelace"
  },
  {
    "parentId": "colors",
    "childId": "heel",
    "name": "Heel",
    "propId": "colors.heel",
    "id": "colors.heel"
  },
  {
    "parentId": "colors",
    "childId": "tongueRunbird",
    "name": "Tongue Runbird",
    "propId": "colors.tongueRunbird",
    "id": "colors.tongueRunbird"
  },
  {
    "parentId": "colors",
    "childId": "tongueTop",
    "name": "Tongue Top",
    "propId": "colors.tongueTop",
    "id": "colors.tongueTop"
  },
  {
    "childId": "decoration",
    "name": "Decoration",
    "id": "decoration"
  },
  {
    "parentId": "decoration",
    "childId": "type",
    "name": "Decoration Type",
    "propId": "decoration.type",
    "id": "decoration.type"
  },
  {
    "parentId": "decoration",
    "childId": "text",
    "name": "Text",
    "propId": "decoration.text",
    "id": "decoration.text"
  },
  {
    "parentId": "decoration",
    "childId": "previewFile",
    "name": "Preview File",
    "propId": "decoration.previewFile",
    "id": "decoration.previewFile"
  },
  {
    "parentId": "decoration",
    "childId": "factoryFile",
    "name": "Factory File",
    "propId": "decoration.factoryFile",
    "id": "decoration.factoryFile"
  },
  {
    "parentId": "decoration",
    "childId": "color",
    "name": "Color",
    "propId": "decoration.color",
    "id": "decoration.color"
  },
  {
    "childId": "details",
    "name": "Roster",
    "id": "details"
  },
  {
    "parentId": "details",
    "childId": "designName",
    "name": "Design Name",
    "propId": "details.recipeName.text",
    "id": "details.designName"
  },
  {
    "parentId": "details",
    "childId": "specialInstructions",
    "name": "Special Instructions",
    "propId": "details.specialInstructions.text",
    "id": "details.specialInstructions"
  },
  {
    "parentId": "details",
    "childId": "roster",
    "name": "Team Roster",
    "propId": "calc.roster",
    "id": "details.roster"
  },
  {
    "parentId": "details",
    "childId": "sizes",
    "name": "Size Summary",
    "propId": "calc.sizes",
    "id": "details.sizes"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<SummaryTreeItemRow[]>> = data;

export type SummaryTreeItem = typeof typed[number];

export const SUMMARY_TREE_ITEM_INDEX_KEY = "id";
export type SummaryTreeItemIndexKey = "id";
export type SummaryTreeItemId = SummaryTreeItem["id"];

let i = 0;
export const SUMMARY_TREE_ITEM_DICT = {
  "product": typed[i++],
  "product.gender": typed[i++],
  "product.cut": typed[i++],
  "product.outsole": typed[i++],
  "colors": typed[i++],
  "colors.midsole": typed[i++],
  "colors.lining": typed[i++],
  "colors.toeTongueCollar": typed[i++],
  "colors.vamp": typed[i++],
  "colors.midfootType": typed[i++],
  "colors.midfootColor": typed[i++],
  "colors.midfootCamouflage": typed[i++],
  "colors.midfootRunbird": typed[i++],
  "colors.shoelace": typed[i++],
  "colors.heel": typed[i++],
  "colors.tongueRunbird": typed[i++],
  "colors.tongueTop": typed[i++],
  "decoration": typed[i++],
  "decoration.type": typed[i++],
  "decoration.text": typed[i++],
  "decoration.previewFile": typed[i++],
  "decoration.factoryFile": typed[i++],
  "decoration.color": typed[i++],
  "details": typed[i++],
  "details.designName": typed[i++],
  "details.specialInstructions": typed[i++],
  "details.roster": typed[i++],
  "details.sizes": typed[i++]
} as const;

export { typed as SUMMARY_TREE };
