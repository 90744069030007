import configureStore from '../../../platform/client/configureStore'
import controlTree from './controlTree'

import updater from './updater'

const store = configureStore({
  updater,
  controlTree,
})

export default store
