import * as _ from '@technically/lodash'

import getAsset from '../../../platform/getAsset'
import { createControlTree } from '../../../platform/client/control-tree'
import Select from '../../../platform/client/control-tree/select'
import Text from '../../../platform/client/control-tree/text'
import FileUpload from '../../../platform/client/control-tree/fileUpload'
import Repeater from '../../../platform/client/control-tree/repeater'

import {
  PRODUCTS,
  GENDERS,
  CUTS,
  OUTSOLE_VARIANTS,
  OUTSOLE_COLORS,
  SOLID_COLORS,
  MIDFOOT_VARIANTS,
  CAMO_COLORS,
  HEEL_DECORATION_TYPES,
  ROSTER_SIZES,
  PROP_DEF_DICT,
  SOLID_COLOR_AREA_DICT,
  SOLID_COLOR_DICT,
  CURRENCY_DICT,
  GENDER_DICT,
  CUT_DICT,
  OUTSOLE_VARIANT_DICT,
  PropDef,
  Gender,
  Cut,
  OutsoleVariant,
  HeelDecorationType,
  MidfootVariant,
  Product,
  SolidColorId,
  SolidColorAreaId,
  CurrencyId,
} from '../common/sheets'

const CURRENCIES: CurrencyId[] = ['USD', 'CAD']

const withPropDef = (propDef: PropDef) => ({
  label: propDef.name,
  subline: propDef.description,
  description: propDef.description,
  defaultValue:
    'defaultValueId' in propDef && propDef.defaultValueId ?
      propDef.defaultValueId
    : null,
  isRequired: !propDef.isOptional,
  showLabel: true,
})

const getColors = (areaId: SolidColorAreaId) => {
  const colorIds = _.compact(
    SOLID_COLOR_AREA_DICT[areaId].limitations.solidColors,
  ) as SolidColorId[]
  return _.map(colorIds, (colorId) => SOLID_COLOR_DICT[colorId])
}

const w = (typeof window !== 'undefined' ? window : {}) as any

const controls = {
  env: {
    currency: Text({
      isPrivate: true,
      value: () => () => {
        const currencyId = w?.serverConfig?.currency ?? 'USD'
        return CURRENCY_DICT[currencyId]
      },
    }),
  },

  product: {
    sku: Select({
      options: PRODUCTS,
      visibleOptions: _.filter(PRODUCTS, (product) => product.props.isEnabled),
    }),
  },

  filters: {
    productsByEnabled: Text({
      isPrivate: true,
      value: () => () => {
        return _.filter(PRODUCTS, (product) => product.props.isEnabled)
      },
    }),

    productsByGender: Text({
      isPrivate: true,
      dependencies: ['filters.productsByEnabled', 'filters.gender'],
      value: (productsByEnabled: Product[], genderFilter?: Gender) => () => {
        return _.filter(productsByEnabled, (product) =>
          genderFilter ? product.props.gender === genderFilter.id : true,
        )
      },
    }),

    productsByCut: Text({
      isPrivate: true,
      dependencies: ['filters.productsByGender', 'filters.cut'],
      value: (productsByGender: Product[], cutFilter?: Cut) => () => {
        return _.filter(productsByGender, (product) =>
          cutFilter ? product.props.cut === cutFilter.id : true,
        )
      },
    }),

    productsByOutsole: Text({
      isPrivate: true,
      dependencies: ['filters.productsByCut', 'filters.outsole'],
      value:
        (productsByCut: Product[], outsoleFilter?: OutsoleVariant) => () => {
          return _.filter(productsByCut, (product) =>
            outsoleFilter ? product.props.outsole === outsoleFilter.id : true,
          )
        },
    }),

    products: Select({
      isPrivate: true,
      dependencies: ['filters.productsByOutsole'],
      options: PRODUCTS,
      visibleOptions: (productsFiltered: Product[]) => {
        return productsFiltered
      },
    }),

    gender: Select({
      ...withPropDef(PROP_DEF_DICT.filters_gender),
      options: GENDERS,
      showLabel: false,
    }),

    cut: Select({
      ...withPropDef(PROP_DEF_DICT.filters_cut),
      dependencies: ['filters.productsByGender'],
      visibleOptions: (products: Product[]) => {
        const cutIds = _.map(products, (product) => product.props.cut)
        return _.filter(CUTS, (cut) => _.includes(cutIds, cut.id))
      },
      options: CUTS,
      showLabel: false,
    }),

    outsole: Select({
      ...withPropDef(PROP_DEF_DICT.filters_outsole),
      dependencies: ['filters.productsByCut'],
      visibleOptions: (products: Product[]) => {
        const outsoleIds = _.map(products, (product) => product.props.outsole)
        return _.filter(OUTSOLE_VARIANTS, (outsole) =>
          _.includes(outsoleIds, outsole.id),
        )
      },
      options: OUTSOLE_VARIANTS,
      showLabel: false,
    }),
  },

  colors: {
    outsole: Select({
      ...withPropDef(PROP_DEF_DICT.colors_outsole),
      dependencies: ['product.sku'],
      visibleOptions: (product: Product) =>
        _.filter(
          OUTSOLE_COLORS,
          (outsoleColor) =>
            !!outsoleColor.availableFor.soleVariant[product.props.outsole],
        ),
      options: OUTSOLE_COLORS,
      defaultValue: (product: Product) => product.defaults.outsoleColor,
    }),

    midsole: Select({
      ...withPropDef(PROP_DEF_DICT.colors_midsole),
      visibleOptions: getColors('midsole'),
      options: SOLID_COLORS,
    }),

    lining: Select({
      ...withPropDef(PROP_DEF_DICT.colors_lining),
      visibleOptions: getColors('lining'),
      options: SOLID_COLORS,
    }),

    toeTongueCollar: Select({
      ...withPropDef(PROP_DEF_DICT.colors_toeTongueCollar),
      dependencies: ['product.sku'],
      visibleOptions: getColors('toeTongueCollar'),
      options: SOLID_COLORS,
      defaultValue: (product: Product) => product.defaults.toeTongueCollar,
    }),

    vamp: Select({
      ...withPropDef(PROP_DEF_DICT.colors_vamp),
      dependencies: ['product.sku'],
      visibleOptions: getColors('vamp'),
      options: SOLID_COLORS,
      defaultValue: (product: Product) => product.defaults.vamp,
    }),

    midfootType: Select({
      ...withPropDef(PROP_DEF_DICT.colors_midfootType),
      options: MIDFOOT_VARIANTS,
    }),

    midfootColor: Select({
      ...withPropDef(PROP_DEF_DICT.colors_midfootColor),
      dependencies: ['colors.midfootType', 'product.sku'],
      isAvailable: (midfootType: MidfootVariant) =>
        midfootType.id === 'solidColor',
      visibleOptions: getColors('midfoot'),
      options: SOLID_COLORS,
      defaultValue: (_midfootType: MidfootVariant, product: Product) =>
        product.defaults.midfootColor,
    }),

    midfootCamouflage: Select({
      ...withPropDef(PROP_DEF_DICT.colors_midfootCamouflage),
      dependencies: ['colors.midfootType'],
      isAvailable: (midfootType: MidfootVariant) =>
        midfootType.id === 'camouflage',
      options: CAMO_COLORS,
      tileType: 'IMAGE',
      getUrl:
        () =>
        ({ id }) => {
          return getAsset(`icons/camos/${id.replace(/^camo_/, '')}.png`)
        },
    }),

    midfootRunbird: Select({
      ...withPropDef(PROP_DEF_DICT.colors_midfootRunbird),
      dependencies: ['product.sku'],
      visibleOptions: getColors('midfootRunbird'),
      options: SOLID_COLORS,
      defaultValue: (product: Product) => product.defaults.midfootRunbird,
    }),

    shoelace: Select({
      ...withPropDef(PROP_DEF_DICT.colors_shoelace),
      dependencies: ['product.sku'],
      visibleOptions: getColors('shoelace'),
      options: SOLID_COLORS,
      defaultValue: (product: Product) => product.defaults.shoelace,
    }),

    heel: Select({
      ...withPropDef(PROP_DEF_DICT.colors_heel),
      dependencies: ['product.sku'],
      visibleOptions: getColors('heel'),
      options: SOLID_COLORS,
      defaultValue: (product: Product) => {
        return product.defaults.heel
      },
    }),

    tongueRunbird: Select({
      ...withPropDef(PROP_DEF_DICT.colors_tongueRunbird),
      visibleOptions: getColors('tongueRunbird'),
      options: SOLID_COLORS,
    }),

    tongueTop: Select({
      ...withPropDef(PROP_DEF_DICT.colors_tongueTop),
      visibleOptions: getColors('tongueTop'),
      options: SOLID_COLORS,
    }),
  },

  decoration: {
    type: Select({
      ...withPropDef(PROP_DEF_DICT.decoration_type),
      options: HEEL_DECORATION_TYPES,
    }),

    text: Text({
      ...withPropDef(PROP_DEF_DICT.decoration_text),
      dependencies: ['decoration.type'],
      isAvailable: (decoType?: HeelDecorationType) => decoType?.id === 'text',
      maxLength: 15,
      pattern: /^[a-zA-Z0-9 #]+$/,
    }),

    previewFile: FileUpload({
      ...withPropDef(PROP_DEF_DICT.decoration_previewFile),
      dependencies: ['decoration.type'],
      isAvailable: (decoType?: HeelDecorationType) => decoType?.id === 'logo',
    }),

    factoryFile: FileUpload({
      ...withPropDef(PROP_DEF_DICT.decoration_factoryFile),
      dependencies: ['decoration.type'],
      isAvailable: (decoType?: HeelDecorationType) => decoType?.id === 'logo',
    }),

    color: Select({
      ...withPropDef(PROP_DEF_DICT.decoration_color),
      dependencies: ['decoration.type'],
      isAvailable: (decoType?: HeelDecorationType) =>
        decoType?.id === 'text' || decoType?.id === 'runbird',
      visibleOptions: getColors('heelDecoration'),
      options: SOLID_COLORS,
    }),
  },

  details: {
    specialInstructions: {
      text: Text({
        ...withPropDef(PROP_DEF_DICT.details_specialInstructions_text),
        maxLength: 120,
      }),
    },

    recipeName: {
      text: Text({
        ...withPropDef(PROP_DEF_DICT.details_recipeName_text),
        maxLength: 30,
      }),
    },

    roster: Repeater({
      defaultRepeats: 1,
      controls: {
        size: Select({
          ...withPropDef(PROP_DEF_DICT.details_roster_size),
          isRequired: false,
          dependencies: ['product.sku'],
          visibleOptions: (product: Product) =>
            _.filter(
              ROSTER_SIZES,
              (size) => !!size.availableFor.product[product.id],
            ),
          options: ROSTER_SIZES,
        }),

        quantity: Text({
          ...withPropDef(PROP_DEF_DICT.details_roster_quantity),
          maxLength: 3,
          pattern: /^[0-9]+$/,
          inputType: 'tel',
        }),

        number: Text({
          ...withPropDef(PROP_DEF_DICT.details_roster_number),
          maxLength: 2,
          pattern: /^[0-9]+$/,
          inputType: 'tel',
        }),

        name: Text({
          ...withPropDef(PROP_DEF_DICT.details_roster_name),
          maxLength: 15,
        }),
      },
    }),
  },

  calc: {
    SKU: Text({
      ...withPropDef(PROP_DEF_DICT.calc_SKU),
      dependencies: ['product.sku'],
      isPrivate: true,
      value: (product: Product) => () => product.id,
    }),

    gender: Text({
      ...withPropDef(PROP_DEF_DICT.calc_gender),
      dependencies: ['product.sku'],
      isPrivate: true,
      value: (product: Product) => () => GENDER_DICT[product.props.gender],
    }),

    cut: Text({
      ...withPropDef(PROP_DEF_DICT.calc_cut),
      dependencies: ['product.sku'],
      isPrivate: true,
      value: (product: Product) => () => CUT_DICT[product.props.cut],
    }),

    outsole: Text({
      ...withPropDef(PROP_DEF_DICT.calc_outsole),
      dependencies: ['product.sku'],
      isPrivate: true,
      value: (product: Product) => () =>
        OUTSOLE_VARIANT_DICT[product.props.outsole],
    }),

    price: Text({
      dependencies: ['product.sku'],
      isPrivate: true,
      value: (product: Product) => () =>
        _.mapValues(
          _.mapKeys(CURRENCIES),
          (currency) => product.props.price[currency],
        ),
    }),

    priceTotal: Text({
      dependencies: ['calc.price', 'details.roster'],
      isPrivate: true,
      value:
        (
          price: { [key in CurrencyId]: number },
          roster: Record<string, Record<string, any>>,
        ) =>
        () => {
          const quantityTotal = _.reduce(
            roster,
            (r, x) => {
              const quantityNode = x.quantity
              return r + (_.parseInt(quantityNode.value) || 0)
            },
            0,
          )
          return _.mapValues(price, (v) => {
            return v * quantityTotal
          })
        },
    }),

    priceFormatted: Text({
      dependencies: ['calc.priceTotal'],
      isPrivate: true,
      value: (priceTotal: { [key in CurrencyId]: number }) => () =>
        _.map(
          CURRENCIES,
          (currency) => `${currency}: ${priceTotal[currency]}`,
        ).join(' | '),
    }),

    isRosterValid: Text({
      dependencies: ['details.roster'],
      isPrivate: true,
      value: (roster: Record<string, Record<string, any>>) => () => {
        const isValid = _.every(
          _.map(roster, 'size'),
          (node) => node.value !== null,
        )
        return isValid
      },
    }),
  },
}

const controlTree = createControlTree(controls)

export default controlTree
