// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { OutsoleColorRow } from '../typings';

const data = [
  {
    "id": "sole_silver",
    "name": "Silver",
    "props": {
      "pantone": "???",
      "hex": "#333333"
    },
    "availableFor": {
      "soleVariant": {
        "metal": true,
        "molded": true,
        "allSurface": false
      }
    }
  },
  {
    "id": "sole_silverChrome",
    "name": "Silver Chrome (Metallic)",
    "props": {
      "pantone": "???",
      "hex": "#666666"
    },
    "availableFor": {
      "soleVariant": {
        "metal": true,
        "molded": false,
        "allSurface": false
      }
    }
  },
  {
    "id": "sole_steelGray",
    "name": "Steel Gray",
    "props": {
      "pantone": "???",
      "hex": "#999999"
    },
    "availableFor": {
      "soleVariant": {
        "metal": false,
        "molded": false,
        "allSurface": true
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<OutsoleColorRow[]>> = data;

export type OutsoleColor = typeof typed[number];

export const OUTSOLE_COLOR_INDEX_KEY = "id";
export type OutsoleColorIndexKey = "id";
export type OutsoleColorId = OutsoleColor["id"];

let i = 0;
export const OUTSOLE_COLOR_DICT = {
  "sole_silver": typed[i++],
  "sole_silverChrome": typed[i++],
  "sole_steelGray": typed[i++]
} as const;

export { typed as OUTSOLE_COLORS };
