import {
  type ArcRotateCamera,
  DefaultRenderingPipeline,
  LensRenderingPipeline,
} from '@babylonjs/core';
import { ok } from '@orangelv/utils';

import { getState, updateState } from './state.js';
import type { Ref, SceneConfig, State } from './types.js';

const createPipeline = (stateRef: Ref<State>) => (): void => {
  const state = getState(stateRef);

  const { scene, props } = state;

  ok(scene);

  const { config } = props;

  const camera = scene.activeCamera as ArcRotateCamera | null;

  ok(camera);

  const defaultRenderingPipeline = new DefaultRenderingPipeline(
    'DefaultRenderingPipeline',
    false,
    scene,
    [camera],
  );

  const antiAliasingConfig: SceneConfig['antiAliasing'] = config.scene
    ?.antiAliasing ?? {
    msaa: 1,
  };

  defaultRenderingPipeline.samples =
    'msaa' in antiAliasingConfig ? antiAliasingConfig.msaa : 0;
  defaultRenderingPipeline.fxaaEnabled =
    'fxaa' in antiAliasingConfig && antiAliasingConfig.fxaa;

  const chromaticAberrationConfig: SceneConfig['chromaticAberration'] =
    config.scene?.chromaticAberration;

  defaultRenderingPipeline.chromaticAberrationEnabled =
    !!chromaticAberrationConfig;
  if (chromaticAberrationConfig) {
    defaultRenderingPipeline.chromaticAberration.aberrationAmount =
      chromaticAberrationConfig.amount;
    defaultRenderingPipeline.chromaticAberration.radialIntensity =
      chromaticAberrationConfig.intensity;
  }

  const grainConfig: SceneConfig['grain'] = config.scene?.grain;

  defaultRenderingPipeline.grainEnabled = !!grainConfig;
  defaultRenderingPipeline.grain.intensity = grainConfig?.intensity ?? 0;
  defaultRenderingPipeline.grain.animated = grainConfig?.animated ?? true;

  updateState(stateRef)({
    defaultRenderingPipeline,
  });

  const edgeBlur = config.scene?.edgeBlur ?? 0;
  const darkenOutOfFocus = config.scene?.darkenOutOfFocus ?? 0;

  // Enabling the LensRenderingPipeline even with every property turned off still makes the scene look too different.
  // To keep the backwards compatibility and improve the performance, enable pipeline only when needed.
  const needsLensRenderingPipeline = edgeBlur !== 0 || darkenOutOfFocus !== 0;

  if (needsLensRenderingPipeline) {
    const lensRenderingPipeline = new LensRenderingPipeline(
      'LensRenderingPipeline',
      {},
      scene,
      1,
      [camera],
    );

    lensRenderingPipeline.edgeBlur = edgeBlur;

    lensRenderingPipeline.darkenOutOfFocus = darkenOutOfFocus;

    updateState(stateRef)({
      lensRenderingPipeline,
    });
  }
};

export default createPipeline;
