import { ROSTER_SIZE_DICT } from './sheets'

type Config = {
  titleBase: string
  titlePostfix: string
  hideVia: boolean
  vendorName: string
}

const getIndexTitle = (config: Config) =>
  `${config.titleBase} ${config.titlePostfix}` +
  `${config.hideVia || !config.vendorName ? '' : ` via ${config.vendorName}`}`

const getIndexDescription = () =>
  'Build your own cleats in a realistic customizer'

const getSkuDescription = () => 'Build your own cleats based on this template.'

const getRecipeDescription = () =>
  'Order these leats or build your own based on them.'

const getSkuValues = (sku: string) => {
  return {
    canonicalSku: sku,
  }
}

const getSkuTitle = (config: Config, params: string[]) => {
  const sku = params[0]

  return `${sku} - ${getIndexTitle(config)}`
}

const getRecipeTitle = (
  config: Config,
  _recipe: any,
  id: string,
  hasChanges: boolean,
) => {
  return `${id}${hasChanges ? ' with changes' : ''} - ${getIndexTitle(config)}`
}

export const garmentSizes = [
  { title: 'Size', path: 'size', sheet: ROSTER_SIZE_DICT },
]

const getStaticTags = () => `
  <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png">
  <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png">
  <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png">
  <link rel="manifest" href="/site.webmanifest">
  <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#001489">
  <meta name="msapplication-TileColor" content="#001489">
  <meta name="theme-color" content="#151923">
`

export {
  getIndexTitle,
  getIndexDescription,
  getSkuValues,
  getSkuTitle,
  getSkuDescription,
  getRecipeTitle,
  getRecipeDescription,
  getStaticTags,
}
