import React from 'react'

import { cn, mods } from '~p/client/components/utils'

import './RosterAdd.css'

function RosterAdd(props) {
  const { children, classMods = [] } = props
  return <div className={cn(['rosterAdd', mods(classMods)])}>{children}</div>
}

export default RosterAdd
