import { Accept } from 'react-dropzone'

import { FileUpload } from './nodes'
import { AnyNodeOpts } from './types'

type FileUploadOpts = AnyNodeOpts & {
  // https://react-dropzone.js.org/#section-accepting-specific-file-types
  accept?: Accept
}

const _FileUpload: (opts: FileUploadOpts) => any = FileUpload

export default _FileUpload
