// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { RosterSizeRow } from '../typings';

const data = [
  {
    "id": "4_0",
    "name": "4",
    "availableFor": {
      "product": {
        "320627": false,
        "320647": false,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": false,
        "320658": false,
        "320659": false,
        "320660": false,
        "320677": false,
        "320678": false,
        "320679": false,
        "320680": false
      }
    }
  },
  {
    "id": "4_5",
    "name": "4.5",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": false,
        "320658": false,
        "320659": false,
        "320660": false,
        "320677": true,
        "320678": true,
        "320679": false,
        "320680": false
      }
    }
  },
  {
    "id": "5_0",
    "name": "5",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": false,
        "320658": false,
        "320659": true,
        "320660": true,
        "320677": true,
        "320678": true,
        "320679": false,
        "320680": false
      }
    }
  },
  {
    "id": "5_5",
    "name": "5.5",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": false,
        "320658": false,
        "320659": true,
        "320660": true,
        "320677": true,
        "320678": true,
        "320679": false,
        "320680": false
      }
    }
  },
  {
    "id": "6_0",
    "name": "6",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": true,
        "320658": true,
        "320659": true,
        "320660": true,
        "320677": true,
        "320678": true,
        "320679": true,
        "320680": true
      }
    }
  },
  {
    "id": "6_5",
    "name": "6.5",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": true,
        "320658": true,
        "320659": true,
        "320660": true,
        "320677": true,
        "320678": true,
        "320679": true,
        "320680": true
      }
    }
  },
  {
    "id": "7_0",
    "name": "7",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": true,
        "320658": true,
        "320659": true,
        "320660": true,
        "320677": true,
        "320678": true,
        "320679": true,
        "320680": true
      }
    }
  },
  {
    "id": "7_5",
    "name": "7.5",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": true,
        "320658": true,
        "320659": true,
        "320660": true,
        "320677": true,
        "320678": true,
        "320679": true,
        "320680": true
      }
    }
  },
  {
    "id": "8_0",
    "name": "8",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": true,
        "320658": true,
        "320659": true,
        "320660": true,
        "320677": true,
        "320678": true,
        "320679": true,
        "320680": true
      }
    }
  },
  {
    "id": "8_5",
    "name": "8.5",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": true,
        "320658": true,
        "320659": true,
        "320660": true,
        "320677": true,
        "320678": true,
        "320679": true,
        "320680": true
      }
    }
  },
  {
    "id": "9_0",
    "name": "9",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": true,
        "320658": true,
        "320659": true,
        "320660": true,
        "320677": true,
        "320678": true,
        "320679": true,
        "320680": true
      }
    }
  },
  {
    "id": "9_5",
    "name": "9.5",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": true,
        "320658": true,
        "320659": true,
        "320660": true,
        "320677": true,
        "320678": true,
        "320679": true,
        "320680": true
      }
    }
  },
  {
    "id": "10_0",
    "name": "10",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": true,
        "320658": true,
        "320659": true,
        "320660": true,
        "320677": true,
        "320678": true,
        "320679": true,
        "320680": true
      }
    }
  },
  {
    "id": "10_5",
    "name": "10.5",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": true,
        "320658": true,
        "320659": true,
        "320660": true,
        "320677": true,
        "320678": true,
        "320679": true,
        "320680": true
      }
    }
  },
  {
    "id": "11_0",
    "name": "11",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": true,
        "320658": true,
        "320659": true,
        "320660": true,
        "320677": true,
        "320678": true,
        "320679": true,
        "320680": true
      }
    }
  },
  {
    "id": "11_5",
    "name": "11.5",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": true,
        "320658": true,
        "320659": true,
        "320660": true,
        "320677": true,
        "320678": true,
        "320679": true,
        "320680": true
      }
    }
  },
  {
    "id": "12_0",
    "name": "12",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": true,
        "320658": true,
        "320659": true,
        "320660": true,
        "320677": true,
        "320678": true,
        "320679": true,
        "320680": true
      }
    }
  },
  {
    "id": "12_5",
    "name": "12.5",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": false,
        "320658": false,
        "320659": false,
        "320660": false,
        "320677": true,
        "320678": true,
        "320679": false,
        "320680": false
      }
    }
  },
  {
    "id": "13_0",
    "name": "13",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": false,
        "320658": false,
        "320659": false,
        "320660": false,
        "320677": true,
        "320678": true,
        "320679": false,
        "320680": false
      }
    }
  },
  {
    "id": "13_5",
    "name": "13.5",
    "availableFor": {
      "product": {
        "320627": false,
        "320647": false,
        "320648": false,
        "320649": false,
        "320650": false,
        "320656": false,
        "320657": false,
        "320658": false,
        "320659": false,
        "320660": false,
        "320677": false,
        "320678": false,
        "320679": false,
        "320680": false
      }
    }
  },
  {
    "id": "14_0",
    "name": "14",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": false,
        "320658": false,
        "320659": false,
        "320660": false,
        "320677": true,
        "320678": true,
        "320679": false,
        "320680": false
      }
    }
  },
  {
    "id": "14_5",
    "name": "14.5",
    "availableFor": {
      "product": {
        "320627": false,
        "320647": false,
        "320648": false,
        "320649": false,
        "320650": false,
        "320656": false,
        "320657": false,
        "320658": false,
        "320659": false,
        "320660": false,
        "320677": false,
        "320678": false,
        "320679": false,
        "320680": false
      }
    }
  },
  {
    "id": "15_0",
    "name": "15",
    "availableFor": {
      "product": {
        "320627": true,
        "320647": true,
        "320648": true,
        "320649": true,
        "320650": true,
        "320656": true,
        "320657": false,
        "320658": false,
        "320659": false,
        "320660": false,
        "320677": true,
        "320678": true,
        "320679": false,
        "320680": false
      }
    }
  },
  {
    "id": "15_5",
    "name": "15.5",
    "availableFor": {
      "product": {
        "320627": false,
        "320647": false,
        "320648": false,
        "320649": false,
        "320650": false,
        "320656": false,
        "320657": false,
        "320658": false,
        "320659": false,
        "320660": false,
        "320677": false,
        "320678": false,
        "320679": false,
        "320680": false
      }
    }
  },
  {
    "id": "16_0",
    "name": "16",
    "availableFor": {
      "product": {
        "320627": false,
        "320647": false,
        "320648": false,
        "320649": false,
        "320650": false,
        "320656": false,
        "320657": false,
        "320658": false,
        "320659": false,
        "320660": false,
        "320677": false,
        "320678": false,
        "320679": false,
        "320680": false
      }
    }
  },
  {
    "id": "16_5",
    "name": "16.5",
    "availableFor": {
      "product": {
        "320627": false,
        "320647": false,
        "320648": false,
        "320649": false,
        "320650": false,
        "320656": false,
        "320657": false,
        "320658": false,
        "320659": false,
        "320660": false,
        "320677": false,
        "320678": false,
        "320679": false,
        "320680": false
      }
    }
  },
  {
    "id": "17_0",
    "name": "17",
    "availableFor": {
      "product": {
        "320627": false,
        "320647": false,
        "320648": false,
        "320649": false,
        "320650": false,
        "320656": false,
        "320657": false,
        "320658": false,
        "320659": false,
        "320660": false,
        "320677": false,
        "320678": false,
        "320679": false,
        "320680": false
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<RosterSizeRow[]>> = data;

export type RosterSize = typeof typed[number];

export const ROSTER_SIZE_INDEX_KEY = "id";
export type RosterSizeIndexKey = "id";
export type RosterSizeId = RosterSize["id"];

let i = 0;
export const ROSTER_SIZE_DICT = {
  "4_0": typed[i++],
  "4_5": typed[i++],
  "5_0": typed[i++],
  "5_5": typed[i++],
  "6_0": typed[i++],
  "6_5": typed[i++],
  "7_0": typed[i++],
  "7_5": typed[i++],
  "8_0": typed[i++],
  "8_5": typed[i++],
  "9_0": typed[i++],
  "9_5": typed[i++],
  "10_0": typed[i++],
  "10_5": typed[i++],
  "11_0": typed[i++],
  "11_5": typed[i++],
  "12_0": typed[i++],
  "12_5": typed[i++],
  "13_0": typed[i++],
  "13_5": typed[i++],
  "14_0": typed[i++],
  "14_5": typed[i++],
  "15_0": typed[i++],
  "15_5": typed[i++],
  "16_0": typed[i++],
  "16_5": typed[i++],
  "17_0": typed[i++]
} as const;

export { typed as ROSTER_SIZES };
