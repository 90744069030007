// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { CamoColorRow } from '../typings';

const data = [
  {
    "id": "camo_green",
    "name": "Green",
    "props": {
      "pantone": [
        "3308 C",
        "555 C",
        "2409 C"
      ],
      "hex": [
        "#024638",
        "#28724f",
        "#4e6548"
      ]
    }
  },
  {
    "id": "camo_white",
    "name": "White",
    "props": {
      "pantone": [
        "White",
        "427 C",
        "429 C"
      ],
      "hex": [
        "#ffffff",
        "#cbd0d3",
        "#9da6ab"
      ]
    }
  },
  {
    "id": "camo_purple",
    "name": "Purple",
    "props": {
      "pantone": [
        "266 C",
        "267 C",
        "269 C"
      ],
      "hex": [
        "#753bbd",
        "#5f249f",
        "#512d6d"
      ]
    }
  },
  {
    "id": "camo_yellow",
    "name": "Yellow",
    "props": {
      "pantone": [
        "106 C",
        "109 C",
        "110 C"
      ],
      "hex": [
        "#f9e547",
        "#ffd100",
        "#daaa00"
      ]
    }
  },
  {
    "id": "camo_black",
    "name": "Black",
    "props": {
      "pantone": [
        "424 C",
        "425 C",
        "Black"
      ],
      "hex": [
        "#707372",
        "#545859",
        "#221e1f"
      ]
    }
  },
  {
    "id": "camo_red",
    "name": "Red",
    "props": {
      "pantone": [
        "185 C",
        "200 C",
        "202 C"
      ],
      "hex": [
        "#e3022a",
        "#ba0c2f",
        "#862633"
      ]
    }
  },
  {
    "id": "camo_navy",
    "name": "Navy",
    "props": {
      "pantone": [
        "654 C",
        "Dress Blues 19-4024TCX",
        "Black"
      ],
      "hex": [
        "#003a70",
        "#2a3244",
        "#000000"
      ]
    }
  },
  {
    "id": "camo_blue",
    "name": "Blue",
    "props": {
      "pantone": [
        "278 C",
        "287 C",
        "2935 C"
      ],
      "hex": [
        "#8bb8e8",
        "#062f87",
        "#0057b7"
      ]
    }
  },
  {
    "id": "camo_orange",
    "name": "Orange",
    "props": {
      "pantone": [
        "163 C",
        "165 C",
        "167 C"
      ],
      "hex": [
        "#ff996b",
        "#fd6110",
        "#b94d0d"
      ]
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<CamoColorRow[]>> = data;

export type CamoColor = typeof typed[number];

export const CAMO_COLOR_INDEX_KEY = "id";
export type CamoColorIndexKey = "id";
export type CamoColorId = CamoColor["id"];

let i = 0;
export const CAMO_COLOR_DICT = {
  "camo_green": typed[i++],
  "camo_white": typed[i++],
  "camo_purple": typed[i++],
  "camo_yellow": typed[i++],
  "camo_black": typed[i++],
  "camo_red": typed[i++],
  "camo_navy": typed[i++],
  "camo_blue": typed[i++],
  "camo_orange": typed[i++]
} as const;

export { typed as CAMO_COLORS };
