import * as _ from '@technically/lodash'
import fp from 'lodash/fp.js'
import React, { Fragment } from 'react'
import { withProps } from 'recompose'
import { connect } from 'react-redux'

import getAsset from '~p/getAsset'
import {
  navListNested,
  hasDrawableChildren,
  firstDrawableChild,
} from '~p/client/navigation'
import * as controlLib from '~p/client/control-tree'
import { openMenu, updateChanges } from '~p/client/common/actions'
import { createMenuSelector } from '~p/client/common/selectors'
import { addNoneToOptions } from '~p/client/component-enhancers'

import controlTree from '~c/client/controlTree'
import store from '~c/client/store'

import NavigationTab from '~mizuno/client/components/atoms/NavigationTab'
import Navigation from '~mizuno/client/components/organisms/Navigation'
import ContentBody from '~mizuno/client/components/atoms/ContentBody'
import Button from '~mizuno/client/components/atoms/Button'
import Roster from '~mizuno/client/components/atoms/Roster'
import RosterRow from '~mizuno/client/components/atoms/RosterRow'
import RosterAdd from '~mizuno/client/components/atoms/RosterAdd'
import Icon from '~mizuno/client/components/atoms/Icon'
import TileSelect from '~mizuno/client/components/TileSelect'
import InfoBox from './InfoBox'

import ToggleBlock from '../../../mizuno-uniforms/client/containers/ToggleBlock'

import * as productControls from '../../../mizuno-uniforms/client/containers/controls'

import _ControlMenu from '../../../mizuno-uniforms/client/containers/ControlMenu'

const TileSelectWithNone = addNoneToOptions(TileSelect)

const withCT = withProps({ controlTree })
const repeatedControl = (path, component) =>
  withCT(controlLib.repeatedControl(path, component))
const {
  ColorSelectControl,
  TileSelectControl,
  FontSelectControl,
  TextInputControl,
  ImageUploadControl,
  FileUploadControl,
  TextareaInputControl,
  SelectInputControl,
} = fp.mapValues(withCT, productControls)
const ControlMenu = withCT(_ControlMenu)

const ControlFragment = withCT(controlLib.Fragment)

const menuSelector = createMenuSelector(controlTree)

const Nav = connect(
  (state, ownProps) => {
    const menu = menuSelector(state)
    return {
      menu,
      ...ownProps,
    }
  },
  {
    openMenu,
  },
)(({ menu, openMenu: openMenuConnected, navOverrides }) => (
  <div>
    <Navigation>
      {_.map(navListNested, (level1) => {
        const { item } = level1

        return (
          <NavigationTab
            key={item.id}
            classStates={menu === item.id ? ['active'] : []}
            onClick={() => {
              const override = navOverrides && navOverrides[item.id]
              if (override) {
                override()
              } else {
                openMenuConnected(controlTree, item.id)
              }
            }}
            data-gtm={`tab-${item.id}`}
          >
            {item.name}
          </NavigationTab>
        )
      })}
    </Navigation>
  </div>
))

const DefaultRoster = (props) => {
  const {
    add,
    controlIds,
    isRemovingDisabled,
    isRosterErrorVisible,
    isRosterValid,
    remove,
    visibleSizes,
  } = props

  let rosterError
  if (isRosterErrorVisible) {
    if (!isRosterValid) {
      rosterError = `Please select the garment size${
        visibleSizes.length > 1 || controlIds.length > 1 ? 's' : ''
      }!`
    }
  }

  return (
    <Fragment>
      <Roster>
        <RosterRow>
          {visibleSizes.map((x) => (
            <td key={x.path}>
              <span>{x.title}</span>
            </td>
          ))}
          <td>
            <span>QTY</span>
          </td>
          <td>
            <span>#</span>
          </td>
          <td>
            <span>Name</span>
          </td>
          <td>
            <span>&nbsp;</span>
          </td>
        </RosterRow>

        {_.map(controlIds, (id) => (
          <RosterRow key={id}>
            {visibleSizes.map((x) => (
              <td className="roster-cell-size" key={x.path}>
                <SelectInputControl
                  path={`details.roster.${id}.${x.path}`}
                  classMods={['fullBorder', 'fullWidth']}
                />
              </td>
            ))}
            <td className="roster-cell-quantity">
              <TextInputControl
                path={`details.roster.${id}.quantity`}
                classMods={['fullBorder']}
              />
            </td>
            <td className="roster-cell-number">
              <TextInputControl
                path={`details.roster.${id}.number`}
                classMods={['fullBorder']}
              />
            </td>
            <td className="roster-cell-name">
              <TextInputControl
                path={`details.roster.${id}.name`}
                classMods={['fullBorder']}
              />
            </td>
            <td className="roster-cell-remove">
              <Button
                classMods={(isRemovingDisabled ? ['disabled'] : []).concat([
                  'action',
                  'remove',
                ])}
                onClick={() => {
                  if (isRemovingDisabled) {
                    return
                  }

                  remove(id)
                }}
              >
                <Icon name="close" />
              </Button>
            </td>
          </RosterRow>
        ))}
      </Roster>

      <RosterAdd>
        <Button classMods={['action', 'add']} onClick={() => add()}>
          <Icon name="plus" />
        </Button>
      </RosterAdd>

      {rosterError && <p className="error">{rosterError}</p>}
    </Fragment>
  )
}

const RosterControl = repeatedControl('details.roster', (props) => {
  const { controlIds, nodes, RosterComponent, sizes, previewId } = props
  const rosterId = previewId ?? controlIds[0]
  const visibleSizes =
    !rosterId ?
      []
    : sizes.reduce((r, x) => {
        const sizeNode = nodes[`details.roster.${rosterId}.${x.path}`]
        return sizeNode.visibleOptions.length > 0 ? [...r, x] : r
      }, [])

  const isRemovingDisabled = controlIds.length === 1

  // Show error only when user has tried saving and there was an error.
  const loc = window.location
  const isRosterErrorVisible = new URLSearchParams(loc.search).has(
    'rosterError',
  )

  // If we have such node, use it. Otherwise default to roster being valid.
  const isRosterValid =
    nodes['calc.isRosterValid'] ? nodes['calc.isRosterValid'].value : true

  const Roster = RosterComponent || DefaultRoster

  return (
    <Roster
      {...props}
      visibleSizes={visibleSizes}
      isRemovingDisabled={isRemovingDisabled}
      isRosterErrorVisible={isRosterErrorVisible}
      isRosterValid={isRosterValid}
    />
  )
})

const getItemComponent = (item, nodes, opts = {}) => {
  const node = item.propId ? nodes[item.propId] : null

  const showLabel = node?.showLabel ?? opts.showLabel
  const hideNone = node?.hideNone ?? opts.hideNone
  const tileType = node?.tileType ?? opts.tileType
  const getUrl = node?.getUrl

  const props = {
    path: item.propId,
    showLabel,
    hideNone,
    tileType,
    getUrl,
  }

  const { contentType } = item

  if (contentType === 'TileSelectControl') {
    return <TileSelectControl key={item.id} {...props} />
  }

  if (contentType === 'ColorSelectControl') {
    return <ColorSelectControl key={item.id} {...props} />
  }

  if (contentType === 'ColorSelectWithTonesControl') {
    const node = nodes[props.path]

    if (!node.isAvailable) {
      return null
    }

    const color = node.object

    let parentId
    if (color) {
      parentId = color.parentId == null ? color.id : color.parentId
    }

    const options = node.visibleOptions || node.options

    const baseOptions = options.filter((x) => x.parentId == null)
    const toneOptions = parentId ? _.filter(options, { parentId }) : []

    return (
      <div key={item.id}>
        <TileSelectWithNone
          type="COLOR"
          options={baseOptions}
          change={(value) => {
            store.dispatch(controlTree.change(props.path, value))
          }}
          resolvedNode={node}
          showLabel
          value={color ? color.id : null}
          appendNone
        />
        <TileSelect
          type="COLOR"
          options={toneOptions}
          change={(value) => {
            store.dispatch(controlTree.change(props.path, value))
          }}
          value={color?.id}
        />
      </div>
    )
  }

  if (contentType === 'FontSelectControl') {
    return <FontSelectControl key={item.id} {...props} />
  }

  if (contentType === 'TextInputControl') {
    if (!item.propId) {
      throw Error('propId missing')
    }
    return (
      <TextInputControl
        key={item.id}
        {...props}
        subline={nodes[item.propId].subline}
        classMods={['gap']}
      />
    )
  }

  if (contentType === 'TextInputForPlacementControl') {
    const { id } = item

    return (
      <TextInputControl
        key={id}
        {...props}
        subline={node?.subline}
        classMods={['gap']}
        primaryLabel={
          id.endsWith('.number') ? 'Number'
          : id.endsWith('.tailText') ?
            'Tail Text'
          : 'Text'
        }
        placeholder="Type here..."
        isOpen
      />
    )
  }

  if (contentType === 'ImageUploadControl') {
    if (!node.isAvailable) return null

    const isJerseyFullCustom = node.keyPath === 'jersey.design.file'
    const isPantsFullCustom = node.keyPath === 'pants.design.file'
    const isFullCustom = isJerseyFullCustom || isPantsFullCustom
    let productId = ''
    if (isJerseyFullCustom) {
      productId = nodes['jersey.sku'].value
    } else if (isPantsFullCustom) {
      productId = nodes['pants.sku'].value
    }
    const fullCustomTemplateFilename = `${productId}-template.svg`

    return (
      <div key={item.id}>
        <ImageUploadControl
          {...props}
          label={null}
          subline={
            node && node.subline ?
              node.subline
            : 'PNG, JPEG or GIF or SVG file for immediate visualization'
          }
          accept={node?.accept}
          classMods={['gap']}
        />
        {isFullCustom && (
          <div className="fullCustomDownloadTemplate">
            Download the full custom design template (
            <a
              href={getAsset(
                `full-custom-templates/${fullCustomTemplateFilename}`,
              )}
              download={fullCustomTemplateFilename}
              target="_blank"
              rel="noreferrer"
            >
              {fullCustomTemplateFilename}
            </a>
            ), open it in Illustrator/Inkscape and follow the instructions.
          </div>
        )}
        {node?.information && (
          <InfoBox type="error" customHeader="WARNING">
            {node.information}
          </InfoBox>
        )}
      </div>
    )
  }

  if (contentType === 'RangeControl') {
    if (!node.isAvailable) return null

    const [min, max] = node.classMods
    const change = (x) => store.dispatch(controlTree.change(node.keyPath, x))

    // For example, when switching from customLogo to teamName, max changes from
    // 5 to 1, so the value needs to be updated if it's more than 1. setTimeout
    // is a hack, because this is the middle of a render, and there's no
    // componentDidMount to put this in. Normally this would reside in a
    // reducer, but this is control tree, too complex.
    if (node.value < min) {
      setTimeout(() => change(min), 0)
    } else if (node.value > max) {
      setTimeout(() => change(max), 0)
    }

    const toString = (x) => String(Math.round((x ?? 1) * 100))
    const toNumber = (x) => Number(x) / 100

    return (
      <div key={item.id} className="RangeControl">
        <div>
          <strong className="tileSet-primaryLabel">{node.subline}</strong>
        </div>

        <div className="inputs">
          <label>
            <input
              type="number"
              min={min * 100}
              max={max * 100}
              step="5"
              value={toString(node.value)}
              onChange={(ev) => change(toNumber(ev.target.value))}
            />
            <span className="unit">%</span>
          </label>

          <input
            type="range"
            min={min * 100}
            max={max * 100}
            step="5"
            value={toString(node.value)}
            onChange={(ev) => change(toNumber(ev.target.value))}
          />
        </div>
      </div>
    )
  }

  if (contentType === 'FileUploadControl') {
    return (
      <FileUploadControl
        key={item.id}
        {...props}
        label={null}
        subline="PSD, AI or TIFF file for factory"
        classMods={['gap']}
      />
    )
  }

  if (contentType === 'CustomColorControl') {
    // TODO: Implement CustomColorControl.
    return <TextInputControl key={item.id} {...props} classMods={['gap']} />
  }

  if (contentType === 'TextareaInputControl') {
    return <TextareaInputControl key={item.id} {...props} />
  }

  if (contentType === 'RosterControl') {
    return (
      <RosterControl
        key={item.id}
        {...props}
        RosterComponent={opts.RosterComponent}
        sizes={opts.garmentSizes}
        isPreviewEnabled={opts.isRosterPreviewEnabled}
        previewId={opts.rosterPreviewId}
        setRosterValues={(values) => {
          store.dispatch(controlTree.setValues(values))
          store.dispatch(updateChanges(controlTree))
        }}
      />
    )
  }

  throw Error(`contentType missing or not handled for ${item.id}`)
}

const Sidebar = (props) => (
  <div id="sidebar">
    <Nav navOverrides={props.navOverrides} />

    {_.map(navListNested, ({ item: item1, children: children1 }) => (
      <ControlFragment forRoute={item1.id} key={item1.id}>
        {() => (
          <div>
            {_.map(children1, ({ item: item2, children: children2 }) => {
              if (children2.length === 0 && item2.propId) {
                const node = props.nodes[item2.propId]

                if (node) {
                  const opts = node.visibleOptions || node.opts

                  if (!node.isAvailable || (opts && opts.length <= 1)) {
                    return null
                  }
                }
              }

              if (item1.id === 'product') {
                return (
                  <ToggleBlock
                    key={item2.id}
                    blockId={item2.id}
                    primaryLabel={item2.name}
                  >
                    {getItemComponent(item2, props.nodes, {
                      hideNone: true,
                    })}
                  </ToggleBlock>
                )
              }
              if (item1.id === 'colors') {
                return (
                  <ContentBody key={item2.id} classMods={['block']}>
                    {getItemComponent(item2, props.nodes)}
                  </ContentBody>
                )
              }

              if (!hasDrawableChildren(item2, children2, props.nodes)) {
                return null
              }

              return (
                <ControlMenu
                  key={item2.id}
                  path={
                    children2.length ?
                      firstDrawableChild(children2, props.nodes).item.propId
                    : item2.propId
                  }
                  link={fp.replace(new RegExp(`^${item1.id}\\.`), '', item2.id)}
                  primaryLabel={item2.name}
                >
                  {!children2.length ?
                    getItemComponent(item2, props.nodes, {
                      RosterComponent: props.RosterComponent,
                      garmentSizes: props.garmentSizes || [
                        { title: 'Size', path: 'size' },
                      ],
                      isRosterPreviewEnabled: props.isRosterPreviewEnabled,
                      rosterPreviewId: props.rosterPreviewId,
                    })
                  : _.map(children2, ({ item: item3 }) =>
                      getItemComponent(item3, props.nodes, {
                        showLabel: true,
                      }),
                    )
                  }
                </ControlMenu>
              )
            })}
          </div>
        )}
      </ControlFragment>
    ))}
  </div>
)

export default Sidebar
export {
  ControlMenu,
  TileSelectControl,
  SelectInputControl,
  TextInputControl,
  getItemComponent,
}
