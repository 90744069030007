import React from 'react'

import { cn, mods } from '~p/client/components/utils'

function RosterRow(props) {
  const { children, classMods = [] } = props

  return <tr className={cn(['roster-row', mods(classMods)])}>{children}</tr>
}

export default RosterRow
