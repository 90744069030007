// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { PropDefRow } from '../typings';

const data = [
  {
    "id": "product_sku",
    "name": "SKU",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "colors_outsole",
    "name": "Outsole",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "colors_midsole",
    "name": "Midsole",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "colors_lining",
    "name": "Lining",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "colors_toeTongueCollar",
    "name": "Toe, Tongue, Collar",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "colors_vamp",
    "name": "Vamp",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "colors_midfootType",
    "name": "Midfoot Type",
    "tileType": "image",
    "isOptional": false
  },
  {
    "id": "colors_midfootColor",
    "name": "Midfoot Color",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "colors_midfootCamouflage",
    "name": "Midfoot Camouflage",
    "tileType": "image",
    "isOptional": false
  },
  {
    "id": "colors_midfootRunbird",
    "name": "Midfoot Runbird",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "colors_shoelace",
    "name": "Shoelace",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "colors_heel",
    "name": "Heel",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "colors_tongueRunbird",
    "name": "Tongue Runbird",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "colors_tongueTop",
    "name": "Tongue Top",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "decoration_type",
    "name": "Content Type",
    "tileType": "name",
    "defaultValueId": "runbird",
    "isOptional": false
  },
  {
    "id": "decoration_text",
    "name": "Text",
    "description": "Only letters, numbers, hashes and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "22 Text",
    "isOptional": false
  },
  {
    "id": "decoration_previewFile",
    "name": "Preview File",
    "tileType": "upload",
    "isOptional": false
  },
  {
    "id": "decoration_factoryFile",
    "name": "Factory File",
    "tileType": "upload",
    "isOptional": true
  },
  {
    "id": "decoration_color",
    "name": "Color",
    "tileType": "color",
    "defaultValueId": "silver",
    "isOptional": false
  },
  {
    "id": "details_specialInstructions_text",
    "name": "Special Instructions",
    "description": "Maximum 120 characters.",
    "tileType": "text",
    "isOptional": true
  },
  {
    "id": "details_recipeName_text",
    "name": "Recipe Name",
    "description": "Maximum 30 characters.",
    "tileType": "text",
    "isOptional": true
  },
  {
    "id": "details_roster_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "details_roster_quantity",
    "name": "Quantity",
    "tileType": "text",
    "defaultValueId": "1",
    "isOptional": false
  },
  {
    "id": "details_roster_number",
    "name": "Number",
    "tileType": "text",
    "isOptional": true
  },
  {
    "id": "details_roster_name",
    "name": "Name",
    "tileType": "text",
    "isOptional": true
  },
  {
    "id": "calc_SKU",
    "name": "SKU",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "calc_roster",
    "name": "Team Roster",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "calc_sizes",
    "name": "Size Summary",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "calc_gender",
    "name": "Gender",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "calc_cut",
    "name": "Cut",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "calc_outsole",
    "name": "Outsole",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "filters_gender",
    "name": "Gender Filter",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "filters_cut",
    "name": "Cut Filter",
    "tileType": "image",
    "isOptional": true
  },
  {
    "id": "filters_outsole",
    "name": "Sole Filter",
    "tileType": "image",
    "isOptional": true
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<PropDefRow[]>> = data;

export type PropDef = typeof typed[number];

export const PROP_DEF_INDEX_KEY = "id";
export type PropDefIndexKey = "id";
export type PropDefId = PropDef["id"];

let i = 0;
export const PROP_DEF_DICT = {
  "product_sku": typed[i++],
  "colors_outsole": typed[i++],
  "colors_midsole": typed[i++],
  "colors_lining": typed[i++],
  "colors_toeTongueCollar": typed[i++],
  "colors_vamp": typed[i++],
  "colors_midfootType": typed[i++],
  "colors_midfootColor": typed[i++],
  "colors_midfootCamouflage": typed[i++],
  "colors_midfootRunbird": typed[i++],
  "colors_shoelace": typed[i++],
  "colors_heel": typed[i++],
  "colors_tongueRunbird": typed[i++],
  "colors_tongueTop": typed[i++],
  "decoration_type": typed[i++],
  "decoration_text": typed[i++],
  "decoration_previewFile": typed[i++],
  "decoration_factoryFile": typed[i++],
  "decoration_color": typed[i++],
  "details_specialInstructions_text": typed[i++],
  "details_recipeName_text": typed[i++],
  "details_roster_size": typed[i++],
  "details_roster_quantity": typed[i++],
  "details_roster_number": typed[i++],
  "details_roster_name": typed[i++],
  "calc_SKU": typed[i++],
  "calc_roster": typed[i++],
  "calc_sizes": typed[i++],
  "calc_gender": typed[i++],
  "calc_cut": typed[i++],
  "calc_outsole": typed[i++],
  "filters_gender": typed[i++],
  "filters_cut": typed[i++],
  "filters_outsole": typed[i++]
} as const;

export { typed as PROP_DEFS };
