import React from 'react'

import { cn, mods } from '~p/client/components/utils'

import './Roster.css'

function Roster(props) {
  const { classMods = [], children } = props
  return (
    <table className={cn(['roster', mods(classMods)])}>
      <tbody>{children}</tbody>
    </table>
  )
}

export default Roster
