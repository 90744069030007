const viewAngles = {
  back: {
    label: 'back',
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
    previewSize: {
      height: 1024,
    },
  },
  front: {
    label: 'front',
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
    previewSize: {
      height: 1024,
    },
  },
  side: {
    label: 'side',
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
    previewSize: {
      height: 1024,
    },
  },
}

const defaultViewAngleId = 'side'

export default viewAngles
export { defaultViewAngleId }
