// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ProductRow } from '../typings';

const data = [
  {
    "id": "320647",
    "name": "MIZUNO SPECTRA METAL MID BB",
    "sku": "32064700000000",
    "props": {
      "isEnabled": true,
      "gender": "mens",
      "cut": "mid",
      "outsole": "metal",
      "price": {
        "USD": 155,
        "CAD": 249.99,
        "EUR": 0
      }
    },
    "defaults": {
      "outsoleColor": "sole_silver",
      "midfootRunbird": "white",
      "heel": "white"
    }
  },
  {
    "id": "320678",
    "name": "MIZUNO SPECTRA METALLIC MID BB",
    "sku": "32067800000000",
    "props": {
      "isEnabled": true,
      "gender": "mens",
      "cut": "mid",
      "outsole": "metal",
      "price": {
        "USD": 175,
        "CAD": 289.99,
        "EUR": 0
      }
    },
    "defaults": {
      "outsoleColor": "sole_silverChrome",
      "midfootRunbird": "white",
      "heel": "white"
    }
  },
  {
    "id": "320649",
    "name": "MIZUNO SPECTRA TPU MID BB",
    "sku": "32064900000000",
    "props": {
      "isEnabled": true,
      "gender": "mens",
      "cut": "mid",
      "outsole": "molded",
      "price": {
        "USD": 135,
        "CAD": 229.99,
        "EUR": 0
      }
    },
    "defaults": {
      "midfootRunbird": "white",
      "heel": "white"
    }
  },
  {
    "id": "320656",
    "name": "MIZUNO SPECTRA AS MID BB",
    "sku": "32065600000000",
    "props": {
      "isEnabled": true,
      "gender": "mens",
      "cut": "mid",
      "outsole": "allSurface",
      "price": {
        "USD": 130,
        "CAD": 219.99,
        "EUR": 0
      }
    },
    "defaults": {
      "midfootRunbird": "white",
      "heel": "white"
    }
  },
  {
    "id": "320627",
    "name": "MIZUNO SPECTRA METAL BB",
    "sku": "32062700000000",
    "props": {
      "isEnabled": true,
      "gender": "mens",
      "cut": "low",
      "outsole": "metal",
      "price": {
        "USD": 145,
        "CAD": 229.99,
        "EUR": 0
      }
    },
    "defaults": {
      "outsoleColor": "sole_silver",
      "midfootRunbird": "white",
      "heel": "white"
    }
  },
  {
    "id": "320677",
    "name": "MIZUNO SPECTRA METALLIC LOW BB",
    "sku": "32067700000000",
    "props": {
      "isEnabled": true,
      "gender": "mens",
      "cut": "low",
      "outsole": "metal",
      "price": {
        "USD": 165,
        "CAD": 279.99,
        "EUR": 0
      }
    },
    "defaults": {
      "outsoleColor": "sole_silverChrome",
      "midfootRunbird": "white",
      "heel": "white"
    }
  },
  {
    "id": "320648",
    "name": "MIZUNO SPECTRA TPU BB",
    "sku": "32064800000000",
    "props": {
      "isEnabled": true,
      "gender": "mens",
      "cut": "low",
      "outsole": "molded",
      "price": {
        "USD": 130,
        "CAD": 219.99,
        "EUR": 0
      }
    },
    "defaults": {
      "midfootRunbird": "white",
      "heel": "white"
    }
  },
  {
    "id": "320650",
    "name": "MIZUNO SPECTRA AS BB",
    "sku": "32065000000000",
    "props": {
      "isEnabled": true,
      "gender": "mens",
      "cut": "low",
      "outsole": "allSurface",
      "price": {
        "USD": 125,
        "CAD": 219.99,
        "EUR": 0
      }
    },
    "defaults": {
      "midfootRunbird": "white",
      "heel": "white"
    }
  },
  {
    "id": "320657",
    "name": "MIZUNO SPECTRA METAL MID FP",
    "sku": "32065700000000",
    "props": {
      "isEnabled": true,
      "gender": "womens",
      "cut": "mid",
      "outsole": "metal",
      "price": {
        "USD": 155,
        "CAD": 249.99,
        "EUR": 0
      }
    },
    "defaults": {
      "outsoleColor": "sole_silver",
      "toeTongueCollar": "white",
      "vamp": "white",
      "midfootColor": "white",
      "shoelace": "white",
      "heel": "white"
    }
  },
  {
    "id": "320680",
    "name": "MIZUNO SPECTRA METALLIC MID FP",
    "sku": "32068000000000",
    "props": {
      "isEnabled": true,
      "gender": "womens",
      "cut": "mid",
      "outsole": "metal",
      "price": {
        "USD": 175,
        "CAD": 289.99,
        "EUR": 0
      }
    },
    "defaults": {
      "outsoleColor": "sole_silverChrome",
      "toeTongueCollar": "white",
      "vamp": "white",
      "midfootColor": "white",
      "shoelace": "white",
      "heel": "white"
    }
  },
  {
    "id": "wmmo",
    "name": "womens / mid / molded",
    "sku": "0",
    "props": {
      "isEnabled": false,
      "gender": "womens",
      "cut": "mid",
      "outsole": "molded",
      "price": {
        "USD": 0,
        "CAD": 0,
        "EUR": 0
      }
    },
    "defaults": {
      "toeTongueCollar": "white",
      "vamp": "white",
      "midfootColor": "white",
      "shoelace": "white",
      "heel": "white"
    }
  },
  {
    "id": "wmas",
    "name": "womens / mid / allSurface",
    "sku": "0",
    "props": {
      "isEnabled": false,
      "gender": "womens",
      "cut": "mid",
      "outsole": "allSurface",
      "price": {
        "USD": 0,
        "CAD": 0,
        "EUR": 0
      }
    },
    "defaults": {
      "toeTongueCollar": "white",
      "vamp": "white",
      "midfootColor": "white",
      "shoelace": "white",
      "heel": "white"
    }
  },
  {
    "id": "320658",
    "name": "MIZUNO SPECTRA METAL FP",
    "sku": "32065800000000",
    "props": {
      "isEnabled": true,
      "gender": "womens",
      "cut": "low",
      "outsole": "metal",
      "price": {
        "USD": 145,
        "CAD": 229.99,
        "EUR": 0
      }
    },
    "defaults": {
      "outsoleColor": "sole_silver",
      "toeTongueCollar": "white",
      "vamp": "white",
      "midfootColor": "white",
      "shoelace": "white",
      "heel": "white"
    }
  },
  {
    "id": "320679",
    "name": "MIZUNO SPECTRA METALLIC LOW FP",
    "sku": "32067900000000",
    "props": {
      "isEnabled": true,
      "gender": "womens",
      "cut": "low",
      "outsole": "metal",
      "price": {
        "USD": 165,
        "CAD": 279.99,
        "EUR": 0
      }
    },
    "defaults": {
      "outsoleColor": "sole_silverChrome",
      "toeTongueCollar": "white",
      "vamp": "white",
      "midfootColor": "white",
      "shoelace": "white",
      "heel": "white"
    }
  },
  {
    "id": "320659",
    "name": "MIZUNO SPECTRA TPU FP",
    "sku": "32065900000000",
    "props": {
      "isEnabled": true,
      "gender": "womens",
      "cut": "low",
      "outsole": "molded",
      "price": {
        "USD": 130,
        "CAD": 219.99,
        "EUR": 0
      }
    },
    "defaults": {
      "toeTongueCollar": "white",
      "vamp": "white",
      "midfootColor": "white",
      "shoelace": "white",
      "heel": "white"
    }
  },
  {
    "id": "320660",
    "name": "MIZUNO SPECTRA AS FP",
    "sku": "32066000000000",
    "props": {
      "isEnabled": true,
      "gender": "womens",
      "cut": "low",
      "outsole": "allSurface",
      "price": {
        "USD": 125,
        "CAD": 219.99,
        "EUR": 0
      }
    },
    "defaults": {
      "toeTongueCollar": "white",
      "vamp": "white",
      "midfootColor": "white",
      "shoelace": "white",
      "heel": "white"
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ProductRow[]>> = data;

export type Product = typeof typed[number];

export const PRODUCT_INDEX_KEY = "id";
export type ProductIndexKey = "id";
export type ProductId = Product["id"];

let i = 0;
export const PRODUCT_DICT = {
  "320647": typed[i++],
  "320678": typed[i++],
  "320649": typed[i++],
  "320656": typed[i++],
  "320627": typed[i++],
  "320677": typed[i++],
  "320648": typed[i++],
  "320650": typed[i++],
  "320657": typed[i++],
  "320680": typed[i++],
  "wmmo": typed[i++],
  "wmas": typed[i++],
  "320658": typed[i++],
  "320679": typed[i++],
  "320659": typed[i++],
  "320660": typed[i++]
} as const;

export { typed as PRODUCTS };
