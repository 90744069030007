/* eslint-disable unicorn/no-null */

import {
  type AbstractEngine,
  type ArcRotateCamera,
  type ICameraInput,
  KeyboardEventTypes,
  type KeyboardInfo,
  type Nullable,
  type Observer,
  PointerEventTypes,
  type PointerInfo,
  type Scene,
  serialize,
} from '@babylonjs/core';

// https://github.com/BabylonJS/Babylon.js/blob/716b858f169e0ea830c1c92cf05263a6569dc2cb/packages/dev/core/src/Cameras/Inputs/arcRotateCameraKeyboardMoveInput.ts
class CustomCameraKeyboardMoveInput implements ICameraInput<ArcRotateCamera> {
  @serialize()
  public keysUp = [38];

  @serialize()
  public keysDown = [40];

  @serialize()
  public keysLeft = [37];

  @serialize()
  public keysRight = [39];

  @serialize()
  public keysReset = [220];

  @serialize()
  public panningSensibility = 50;

  @serialize()
  public angularSpeed = 0.01;

  public camera: Nullable<ArcRotateCamera> = null;
  private readonly keys = new Array<number>();
  private onCanvasBlurObserver: Nullable<Observer<AbstractEngine>> = null;
  private onKeyboardObserver: Nullable<Observer<KeyboardInfo>> = null;
  private engine: Nullable<AbstractEngine> = null;
  private scene: Nullable<Scene> = null;

  public attachControl(noPreventDefault?: boolean): void {
    if (this.onCanvasBlurObserver) {
      return;
    }

    if (!this.camera) throw new Error('Camera not set');

    this.scene = this.camera.getScene();
    this.engine = this.scene.getEngine();

    this.onCanvasBlurObserver = this.engine.onCanvasBlurObservable.add(() => {
      this.keys.length = 0;
    });

    this.onKeyboardObserver = this.scene.onKeyboardObservable.add((info) => {
      const { event } = info;
      const { keyCode } = event;

      if (event.metaKey) {
        return;
      }

      if (info.type === KeyboardEventTypes.KEYDOWN) {
        if (
          this.keysUp.includes(keyCode) ||
          this.keysDown.includes(keyCode) ||
          this.keysLeft.includes(keyCode) ||
          this.keysRight.includes(keyCode) ||
          this.keysReset.includes(keyCode)
        ) {
          const index = this.keys.indexOf(keyCode);

          if (index === -1) {
            this.keys.push(keyCode);
          }

          if (!noPreventDefault) {
            event.preventDefault();
          }
        }
      } else if (
        this.keysUp.includes(keyCode) ||
        this.keysDown.includes(keyCode) ||
        this.keysLeft.includes(keyCode) ||
        this.keysRight.includes(keyCode) ||
        this.keysReset.includes(keyCode)
      ) {
        const index = this.keys.indexOf(keyCode);

        if (index >= 0) {
          this.keys.splice(index, 1);
        }

        if (!noPreventDefault) {
          event.preventDefault();
        }
      }
    });
  }

  public detachControl(): void {
    if (this.scene) {
      if (this.onKeyboardObserver) {
        this.scene.onKeyboardObservable.remove(this.onKeyboardObserver);
      }

      if (this.onCanvasBlurObserver && this.engine) {
        this.engine.onCanvasBlurObservable.remove(this.onCanvasBlurObserver);
      }

      this.onKeyboardObserver = null;
      this.onCanvasBlurObserver = null;
    }

    this.keys.length = 0;
  }

  public checkInputs(): void {
    if (this.onKeyboardObserver) {
      const { camera } = this;
      if (!camera) throw new Error('Camera not set');

      for (const keyCode of this.keys) {
        if (this.keysLeft.includes(keyCode)) {
          camera.inertialAlphaOffset -= this.angularSpeed;
        } else if (this.keysUp.includes(keyCode)) {
          camera.inertialBetaOffset -= this.angularSpeed;
        } else if (this.keysRight.includes(keyCode)) {
          camera.inertialAlphaOffset += this.angularSpeed;
        } else if (this.keysDown.includes(keyCode)) {
          camera.inertialBetaOffset += this.angularSpeed;
        } else if (this.keysReset.includes(keyCode)) {
          camera.restoreState();
        }
      }
    }
  }

  public getClassName(): string {
    return 'CustomCameraKeyboardMoveInput';
  }

  public getSimpleName(): string {
    return 'keyboard';
  }
}

// https://github.com/BabylonJS/Babylon.js/blob/716b858f169e0ea830c1c92cf05263a6569dc2cb/packages/dev/core/src/Cameras/Inputs/arcRotateCameraMouseWheelInput.ts
class CustomCameraMouseWheelInput implements ICameraInput<ArcRotateCamera> {
  @serialize()
  public wheelPrecision = 1;

  public camera: Nullable<ArcRotateCamera> = null;
  private pointerObserver: Nullable<Observer<PointerInfo>> = null;

  public attachControl(noPreventDefault?: boolean): void {
    if (!this.camera) throw new Error('Camera not set');
    const scene = this.camera.getScene();
    this.pointerObserver = scene.onPointerObservable.add((info) => {
      if (info.type === PointerEventTypes.POINTERWHEEL) {
        const wheelDelta = (info.event as WheelEvent).deltaY > 0 ? -100 : 100;
        const delta = wheelDelta / (this.wheelPrecision * 40);
        if (!this.camera) throw new Error('Camera not set');
        this.camera.inertialRadiusOffset += delta;
      }

      if (!noPreventDefault) info.event.preventDefault();
    });
  }

  public detachControl(): void {
    if (this.pointerObserver) {
      if (!this.camera) throw new Error('Camera not set');
      this.camera.getScene().onPointerObservable.remove(this.pointerObserver);
      this.pointerObserver = null;
    }
  }

  public getClassName(): string {
    return 'CustomCameraMouseWheelInput';
  }

  public getSimpleName(): string {
    // This must be `mousewheel`, otherwise `wheelPrecision` setter won't work.
    return 'mousewheel';
  }
}

export { CustomCameraKeyboardMoveInput, CustomCameraMouseWheelInput };
