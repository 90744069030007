import * as _ from '@technically/lodash'
import React, { useRef, useEffect, useState } from 'react'

import {
  BjsRenderer,
  RendererConfig,
  Camera,
  Cameras,
  SetCamera,
} from '@orangelv/bjs-renderer'

import assert from '../../../platform/assert'

import LoadingIndicator from '../../_mizuno/client/components/atoms/LoadingIndicator'
import ViewNavigation from '../../_mizuno/client/components/atoms/ViewNavigation'
import Button from '../../_mizuno/client/components/atoms/Button'
import Icon from '../../_mizuno/client/components/atoms/Icon'
import onGeneratePreviews from './onGeneratePreviews'

import './Renderer.css'

type Props = {
  config: RendererConfig
  isHidden: boolean
  autoResize: string
  setPreviewGenerator: (
    previewGenerator: (missingViews: string[]) => Promise<Record<string, Blob>>,
  ) => void
  isLoading: boolean
  setLoading: (isLoading: boolean) => void
}

const Renderer = (props: Props) => {
  const { config, isHidden, autoResize, isLoading, setLoading } = props

  const propsRef = useRef(props)

  useEffect(() => {
    propsRef.current = props
  }, [props])

  const setCameraRef = useRef<SetCamera>()
  const setCamera = setCameraRef.current

  const [availableCameras, setAvailableCameras] = useState<Cameras>([])

  const defaultCamera = config.camera?.defaultCamera as string | undefined

  assert(defaultCamera, 'Default camera not defined!')

  const [activeCamera, setActiveCamera] = useState<Camera['id'] | undefined>(
    defaultCamera,
  )

  return (
    <div
      id="renderer"
      style={{ display: isHidden ? 'none' : 'block', touchAction: 'none' }}
    >
      {isLoading && (
        <div className="loader-wrapper">
          <LoadingIndicator />
        </div>
      )}

      <BjsRenderer
        config={config}
        onLoading={(isLoading) => {
          setLoading(isLoading)
        }}
        onCameras={(cameras) => {
          const cameraOrder = ['back', 'side', 'front']

          setAvailableCameras(
            _.sortBy(cameras, (camera) => {
              const foundIndex = cameraOrder.indexOf(camera.id)
              if (foundIndex === -1) {
                // So that unknown cameras are at the end.
                return cameras.length
              }
              return foundIndex
            }),
          )
        }}
        onCameraReset={() => {
          setActiveCamera(undefined)
        }}
        onSetCamera={(setCamera) => {
          setCameraRef.current = setCamera
        }}
        onGeneratePreviews={onGeneratePreviews(propsRef, defaultCamera)}
        onLog={(level, message) => {
          const formattedMessage = `${_.toUpper(level)}: ${message}`
          if (level === 'verbose') {
            console.info(formattedMessage)
          } else {
            console.log(formattedMessage)
          }
        }}
        onLogGroup={(starts) => {
          if (starts) {
            console.group('bjsRenderer')
          } else {
            console.groupEnd()
          }
        }}
        isHidden={isHidden}
        autoResize={autoResize}
      />

      {!isLoading && (
        <ViewNavigation>
          {_.map(availableCameras, (camera) => (
            <Button
              key={camera.id}
              classMods={['view']}
              classStates={camera.id === activeCamera ? ['active'] : []}
              onClick={() => {
                setActiveCamera(camera.id)
                if (setCamera) {
                  setCamera(camera.id)
                }
              }}
            >
              {camera.name}
            </Button>
          ))}
          <div className="renderer-help">
            <Icon name="3d" />
            <span className="help-text is-desktop">
              Click and drag to rotate, mouse wheel to zoom
            </span>
            <span className="help-text is-mobile">
              Swipe to rotate, pinch to zoom
            </span>
          </div>
        </ViewNavigation>
      )}
    </div>
  )
}

export { Renderer, type Props }
