// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { SolidColorAreaRow } from '../typings';

const data = [
  {
    "id": "midsole",
    "name": "Midsole",
    "limitations": {
      "solidColors": [
        "white"
      ]
    }
  },
  {
    "id": "lining",
    "name": "Lining (mesh)",
    "limitations": {
      "solidColors": [
        "silver"
      ]
    }
  },
  {
    "id": "toeTongueCollar",
    "name": "Toe, Tong And Collar",
    "limitations": {
      "solidColors": [
        "black",
        "white",
        "red",
        "royal",
        "navy",
        "darkShadow"
      ]
    }
  },
  {
    "id": "vamp",
    "name": "Vamp",
    "limitations": {
      "solidColors": [
        "black",
        "white",
        "red",
        "royal",
        "navy",
        "darkShadow"
      ]
    }
  },
  {
    "id": "midfoot",
    "name": "Midfoot",
    "limitations": {
      "solidColors": [
        "black",
        "white",
        "red",
        "royal",
        "gray",
        "navy",
        "forestGreen",
        "kellyGreen",
        "yellow",
        "orange",
        "maroon",
        "purple",
        "lightBlue",
        "vegasGold"
      ]
    }
  },
  {
    "id": "midfootRunbird",
    "name": "Midfoot Runbird",
    "limitations": {
      "solidColors": [
        "black",
        "white",
        "red",
        "royal",
        "gray",
        "navy",
        "kellyGreen",
        "orange",
        "lightBlue"
      ]
    }
  },
  {
    "id": "shoelace",
    "name": "Shoelace",
    "limitations": {
      "solidColors": [
        "black",
        "white",
        "red",
        "royal",
        "gray",
        "navy",
        "forestGreen",
        "kellyGreen",
        "yellow",
        "orange",
        "maroon",
        "purple",
        "lightBlue",
        "vegasGold"
      ]
    }
  },
  {
    "id": "heel",
    "name": "Heel",
    "limitations": {
      "solidColors": [
        "black",
        "white",
        "red",
        "royal",
        "gray",
        "navy",
        "forestGreen",
        "kellyGreen",
        "yellow",
        "orange",
        "maroon",
        "purple",
        "lightBlue",
        "vegasGold"
      ]
    }
  },
  {
    "id": "tongueRunbird",
    "name": "Tongue Runbird",
    "limitations": {
      "solidColors": [
        "silver"
      ]
    }
  },
  {
    "id": "tongueTop",
    "name": "Tongue Top",
    "limitations": {
      "solidColors": [
        "silver"
      ]
    }
  },
  {
    "id": "heelDecoration",
    "name": "Heel Decoration",
    "limitations": {
      "solidColors": [
        "black",
        "white",
        "red",
        "royal",
        "gray",
        "navy",
        "forestGreen",
        "kellyGreen",
        "yellow",
        "orange",
        "maroon",
        "purple",
        "lightBlue",
        "vegasGold"
      ]
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<SolidColorAreaRow[]>> = data;

export type SolidColorArea = typeof typed[number];

export const SOLID_COLOR_AREA_INDEX_KEY = "id";
export type SolidColorAreaIndexKey = "id";
export type SolidColorAreaId = SolidColorArea["id"];

let i = 0;
export const SOLID_COLOR_AREA_DICT = {
  "midsole": typed[i++],
  "lining": typed[i++],
  "toeTongueCollar": typed[i++],
  "vamp": typed[i++],
  "midfoot": typed[i++],
  "midfootRunbird": typed[i++],
  "shoelace": typed[i++],
  "heel": typed[i++],
  "tongueRunbird": typed[i++],
  "tongueTop": typed[i++],
  "heelDecoration": typed[i++]
} as const;

export { typed as SOLID_COLOR_AREAS };
